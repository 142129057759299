import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
}));

export default function TopAlert(props) {
  const {title, children, severity} = props;
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <Alert severity={severity}>
        {title && <AlertTitle>{title}</AlertTitle>}
        {children}
      </Alert>
    </div>
  );
}

import React, {useState} from 'react';
import {ResizableBox as ReactResizableBox} from 'react-resizable';

import 'react-resizable/css/styles.css';

export default function ResizableBox({
  children,
  width = 500,
  height = 300,
  resizable = true,
  style = {},
  className,
}) {
  const [resizing, setResizing] = useState(false);

  return (
    <div>
      {resizable ? (
        <ReactResizableBox
          width={width}
          height={height}
          onResizeStart={() => setResizing(true)}
          onResizeStop={() => setResizing(false)}>
          <div
            style={{
              ...style,
              width: '100%',
              height: '100%',
            }}
            className={className}>
            {resizing ? null : children}
          </div>
        </ReactResizableBox>
      ) : (
        <div
          style={{
            width: `${width}px`,
            height: `${height}px`,
            ...style,
          }}
          className={className}>
          {children}
        </div>
      )}
    </div>
  );
}

import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import ListSubheader from '@material-ui/core/ListSubheader';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import {showNotification} from 'react-admin';
import {useDispatch} from 'react-redux';
import config from 'app.config';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
  },
  gridList: {
    width: '100%',
    margin: '30px 30px 0!important',
    maxWidth: 1500,
  },
  header: {
    fontSize: 18,
    fontWeight: 700,
    padding: 0,
    margin: 0,
  },
  figure: {
    cursor: 'pointer',
  },
  bar: {
    backgroundColor: 'rgb(0 177 176 / 0.5)',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalCard: {
    width: '80%',
    height: '80%',
  },
  video: {
    width: '100%',
    height: '100%',
  },
}));

const videoData = [
  {
    plId: 'PLGLad_5COq5C7tYSVPDEqknwT7nc7_Ali',
    title: 'Rifiutoo per Produttori rifiuti',
  },
];

export default function Video() {
  const max740 = useMediaQuery('(max-width:740px)');
  const min1200 = useMediaQuery('(min-width:1200px)');
  const classes = useStyles();
  const [activeVideo, setActiveVideo] = React.useState(null);
  const [videos, setVideos] = React.useState([]);
  const dispatch = useDispatch();

  const handleOpen = (value) => {
    setActiveVideo(value);
  };

  const handleClose = () => {
    setActiveVideo(null);
  };

  useEffect(() => {
    const fetchVideosFromPlaylist = async (plId) => {
      try {
        const url = new URL(
          'https://www.googleapis.com/youtube/v3/playlistItems',
        );
        url.searchParams.append('key', config('gMap'));
        url.searchParams.append('playlistId', plId);
        url.searchParams.append('part', 'snippet');
        url.searchParams.append('maxResults', 50);
        return fetch(url);
      } catch (e) {
        dispatch(showNotification('errors.INTERNAL_SERVER_ERROR'), 'error');
      }
    };

    const fetchAllPlayistsVideos = async () => {
      try {
        const proms = videoData.map((vD) => fetchVideosFromPlaylist(vD.plId));
        const rawRes = await Promise.all(proms);
        const res = await Promise.all(rawRes.map((r) => r.json()));
        setVideos(res.map((r) => r.items));
      } catch (e) {
        dispatch(showNotification('errors.INTERNAL_SERVER_ERROR'), 'error');
      }
    };

    fetchAllPlayistsVideos();
  }, [dispatch]);

  return (
    <div className={classes.root}>
      {videoData.map((section, idx) => (
        <GridList
          key={section.plId}
          cellHeight={max740 ? 300 : 240}
          cols={min1200 ? 3 : !max740 ? 2 : 1}
          spacing={20}
          className={classes.gridList}>
          <GridListTile key="Subheader" cols={3} style={{height: 'auto'}}>
            <ListSubheader component="h2" className={classes.header}>
              {section.title}
            </ListSubheader>
          </GridListTile>
          {videos[idx]?.map((v) => (
            <GridListTile
              key={v.id}
              onClick={() => handleOpen(v)}
              className={classes.figure}>
              <img
                src={v.snippet?.thumbnails?.standard?.url || ''}
                alt="video preview"
              />
            </GridListTile>
          ))}
        </GridList>
      ))}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={Boolean(activeVideo)}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        {activeVideo && (
          <Fade in={activeVideo}>
            <Card className={classes.modalCard}>
              <CardMedia
                component="iframe"
                src={`https://www.youtube.com/embed/${activeVideo?.snippet?.resourceId?.videoId}`}
                className={classes.video}
              />
            </Card>
          </Fade>
        )}
      </Modal>
    </div>
  );
}

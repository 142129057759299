import React from 'react';
import {useSelector} from 'react-redux';
import {MenuItemLink, getResources, useTranslate} from 'react-admin';
import DefaultIcon from '@material-ui/icons/ViewList';

const WHITELISTED_RESOURCES = [
  'User',
  'Organization',
  'Partnership',
  'WasteOperation',
  'EerCode',
  'AlertIcon'
];

export default function AdminMenuItems({onMenuClick, dense, classes}) {
  const resources = useSelector(getResources);
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const translate = useTranslate();

  const menuItemProps = {
    onClick: onMenuClick,
    sidebarIsOpen: open,
    classes: {active: classes.activeMenuItem},
    dense,
  };

  return (
    <>
      {resources
        .filter(({name}) => WHITELISTED_RESOURCES.includes(name))
        .map((resource) => {
          return (
            <MenuItemLink
              {...menuItemProps}
              key={resource.name}
              to={`/${resource.name}`}
              primaryText={
                (resource.options && resource.options.label) ||
                translate(`resources.${resource.name}.name`, {
                  smart_count: 0,
                  _: resource.name,
                })
              }
              leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
            />
          );
        })}
    </>
  );
}

import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {Authenticated} from 'react-admin';
import {
  ProfileEdit,
  Onboarding,
  ConsultantCustomer,
  Changelog,
  Video,
} from 'routes';
import Analytics from 'routes/Analytics';
import Stocks from 'routes/Stocks/Stocks';
import AlertShow from 'routes/AlertShow';

export default [
  <Route
    key="me"
    path="/me"
    render={() => (
      <Authenticated>
        <ProfileEdit />
      </Authenticated>
    )}
  />,
  <Route key="changelog" path="/news" render={() => <Changelog />} />,
  <Route
    key="onboarding"
    noLayout
    path="/onboarding"
    render={() => (
      <Authenticated>
        <Onboarding />
      </Authenticated>
    )}
  />,
  <Route
    key="consultant-customer"
    path="/ConsultantCustomer"
    render={() => (
      <Authenticated>
        <ConsultantCustomer />
      </Authenticated>
    )}
  />,
  <Route
    key="analytics"
    path="/analytics"
    render={() => (
      <Authenticated>
        <Analytics />
      </Authenticated>
    )}
  />,
  <Route
    path="/stocks"
    render={() => (
      <Authenticated>
        <Stocks />
      </Authenticated>
    )}
  />,
  <Route
    key="video"
    path="/video"
    render={() => (
      <Authenticated>
        <Video />
      </Authenticated>
    )}
  />,
  <Route
    key="alert_show"
    path="/Alert/:id/show"
    render={() => (
      <Authenticated>
        <AlertShow />
      </Authenticated>
    )}
  />,
];

import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import {makeStyles, ThemeProvider} from '@material-ui/core/styles';
import theme from 'theme';

import Logo from './Logo';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100vw',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    [theme.breakpoints.down('lg')]: {
      marginTop: '-3em',
    },
  },
  content: {
    opacity: 0.5,
    margin: '0 1em',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  logo: {
    display: 'block',
  },
}));

const AppLoader = () => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.container}>
        <div className={classes.content}>
          <CircularProgress />
          <Logo className={classes.logo} />
        </div>
      </div>
    </ThemeProvider>
  );
};

export default AppLoader;

import React from 'react';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Inbox from '@material-ui/icons/Inbox';
import {useTranslate, useListContext, CreateButton} from 'react-admin';
import inflection from 'inflection';

const useStyles = makeStyles(
  (theme) => ({
    message: {
      textAlign: 'center',
      opacity: theme.palette.type === 'light' ? 0.5 : 0.8,
      margin: '0 1em',
      color:
        theme.palette.type === 'light' ? 'inherit' : theme.palette.text.primary,
    },
    icon: {
      width: '9em',
      height: '9em',
    },
    toolbar: {
      textAlign: 'center',
      marginTop: '2em',
    },
  }),
  {name: 'RaEmpty'},
);

const Empty = ({create = true, ...others}) => {
  const {resource, basePath} = useListContext({...others});
  const classes = useStyles(others);
  const translate = useTranslate();

  const resourceName = translate(`resources.${resource}.forcedCaseName`, {
    smart_count: 0,
    _: inflection.humanize(
      translate(`resources.${resource}.name`, {
        smart_count: 0,
        _: inflection.pluralize(resource),
      }),
      true,
    ),
  });

  const emptyMessage = translate('ra.page.empty', {name: resourceName});
  const inviteMessage = translate('ra.page.invite');

  return (
    <>
      <div className={classes.message}>
        <Inbox className={classes.icon} />
        <Typography variant="h4" paragraph>
          {translate(`resources.${resource}.empty`, {_: emptyMessage})}
        </Typography>
        <Typography variant="body1">
          {translate(`resources.${resource}.invite`, {
            _: inviteMessage,
          })}
        </Typography>
      </div>
      {create && (
        <div className={classes.toolbar}>
          {typeof create === 'boolean' ? (
            <CreateButton variant="contained" basePath={basePath} />
          ) : (
            create
          )}
        </div>
      )}
    </>
  );
};

export default Empty;

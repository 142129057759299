export default {
  ra: {
    action: {
      unselect: 'Deseleziona',
      back: 'Torna indietro',
      archive: 'Archivia',
    },
    page: {
      empty: 'Nessun risultato trovato',
      invite: 'Crea un elemento per visualizzarlo in questo elenco.',
      dashboard: 'Dashboard',
    },
    input: {
      password: {
        toggle_hidden: 'Mostra',
        toggle_visible: 'Nascondi',
      },
    },
    message: {
      archive_title: 'Archivia %{name}',
      archive_content: 'Sei sicuro di voler procedere?',
      delete_title: 'Cancella %{name}',
      delete_content:
        'Attenzione! Questa azione è irreversibile. Sei sicuro di voler procedere?',
    },
    notification: {
      archived: 'Record archiviato',
    },
    validation: {
      length: 'Deve contenere %{length} caratteri',
      waste_register_full:
        'Le pagine a disposizione in questo registro non sono sufficienti a contenere le operazioni selezionate. Seleziona al massimo %{max} operazioni oppure seleziona un altro registro.',
    },
  },
  resources: {
    Authorization: {
      name: 'Autorizzazione |||| Autorizzazioni',
      fields: {
        number: 'Numero',
        activity: 'Attività',
        category: 'Categoria',
        subCategory: 'Sottocategoria',
        class: 'Classe',
        issuedOn: 'Rilasciata il',
        startFrom: 'Valida dal',
        expireOn: 'Scade il',
        localUnit: 'Unità locale',
        file: 'File',
        notes: 'Note',
        archived: 'Archiviata',
        archivedAt: 'Archiviato il',
      },
    },
    EerCode: {
      name: 'Codice EER |||| Codici EER',
      fields: {
        id: 'Codice',
        description: 'Descrizione',
      },
    },
    AlertIcon: {
      name: 'Icona Segnaletica |||| Icone Segnaletica',
      fields: {
        name: 'Descrizione',
        bucket: 'Url risorsa',
      },
    },
    User: {
      name: 'Utente |||| Utenti',
      fields: {
        firstName: 'Nome',
        lastName: 'Cognome',
        jobTitle: 'Ruolo aziendale',
        email: 'Email',
        phone: 'Telefono',
        verified: 'Verificato',
        enabled: 'Abilitato',
      },
    },
    UserInvite: {
      name: 'Invito |||| Inviti',
      stream: {
        create:
          '%{actor} ti ha invitato ad utilizzare Rifiutoo |||| Hai ricevuto %{smart_count} inviti ad utilizzare Rifiutoo',
      },
    },
    Membership: {
      name: 'Membership |||| Memberships',
      fields: {
        organization: 'Organizzazione',
        localUnit: 'Unità locale',
        user: 'Utente',
        role: 'Ruolo',
      },
      stream: {
        create_user:
          'Sei stato abilitato ad operare per conto di %{context} |||| Sei stato abilitato ad operare per conto di %{smart_count} organizzazioni e/o unità locali',
        delete_user:
          'Non sei più abilitato ad operare per conto di %{context} |||| Non sei più abilitato ad operare per conto di %{smart_count} organizzazioni e/o unità locali',
        create:
          '%{actor} ha aggiunto un nuovo contatto |||| %{actor} ha aggiunto %{smart_count} nuovi contatti',
        delete:
          '%{actor} ha rimosso un contatto |||| %{actor} ha rimosso %{smart_count} contatti',
      },
    },
    MembershipMember: {
      name: 'Contatto |||| Contatti',
      fields: {
        firstName: 'Nome',
        lastName: 'Cognome',
        displayName: 'Nome completo',
        jobTitle: 'Ruolo aziendale',
        email: 'Email',
        phone: 'Telefono',
      },
    },
    Organization: {
      name: 'Organizzatione |||| Organizzazioni',
      fields: {
        name: 'Ragione sociale',
        type: 'Tipologia',
        public: 'Pubblica',
        taxId: 'Partita IVA',
        fiscalCode: 'Codice Fiscale',
        phone: 'Telefono',
        pec: 'Pec',
        sdi: 'Sdi',
        ateco: 'Codice Ateco',
        legalRepresentative: {
          firstName: 'Nome',
          lastName: 'Cognome',
          fiscalCode: 'Codice Fiscale',
        },
      },
    },
    LocalUnit: {
      name: 'Unità locale |||| Unità locali',
      fields: {
        name: 'Identificativo U.L.',
        email: 'Email',
        organization: 'Ragione sociale',
        phone: 'Telefono',
        pec: 'Pec',
        ateco: 'Codice Ateco',
        rea: 'Numero Rea',
        employeeNumber: 'Numero dipendenti',
        monthsOfBusiness: 'Mesi di attività',
        settings: {
          stockType: 'Criterio di gestione deposito temporaneo',
        },
      },
    },
    LocalUnitDocument: {
      name: 'Documento fiscale |||| Documenti fiscali',
      fields: {
        name: 'Titolo',
        file: 'Documento',
        category: 'Categoria',
        provider: 'Fornitore',
        localUnit: 'Unità locale',
        createdAt: 'Caricato il',
        archived: 'Archiviato',
        archivedAt: 'Archiviato il',
      },
    },
    LocalUnitSite: {
      name: 'Cantiere |||| Cantieri',
      fields: {
        name: 'Identificativo cantiere',
        activity: 'Attività',
        phone: 'Telefono',
        email: 'Email',
        manager: 'Responsabile',
        localUnit: 'Unità locale collegata',
      },
    },
    Provider: {
      name: 'Fornitore |||| Fornitori',
      fields: {
        name: 'Ragione sociale',
        public: 'Pubblica',
        taxId: 'Partita IVA',
        fiscalCode: 'Codice Fiscale',
        phone: 'Telefono',
        pec: 'Pec',
        sdi: 'Sdi',
        ateco: 'Codice Ateco',
        legalRepresentative: {
          firstName: 'Nome',
          lastName: 'Cognome',
          fiscalCode: 'Codice Fiscale',
        },
      },
    },
    ProviderUnit: {
      name: 'Unità Locale del fornitore |||| Unità Locali del fornitore',
      fields: {
        name: 'Identificativo U.L.',
        email: 'Email',
        organization: 'Ragione sociale',
        phone: 'Telefono',
        pec: 'Pec',
        ateco: 'Codice Ateco',
        address: 'Indirizzo',
        rea: 'Numero Rea',
        employeeNumber: 'Numero dipendenti',
        monthsOfBusiness: 'Mesi di attività',
      },
    },
    ProviderAuthorization: {
      name: 'Autorizzazione |||| Autorizzazioni',
      fields: {
        number: 'Numero autorizzazione',
        activity: 'Attività',
        category: 'Categoria',
        subCategory: 'Sottocategoria',
        class: 'Classe',
        issuedOn: 'Rilasciata il',
        startFrom: 'Valida dal',
        expireOn: 'Scade il',
        localUnit: 'Unità locale',
        file: 'File',
        notes: 'Note',
      },
    },
    Partnership: {
      empty: 'Nessun fornitore trovato',
      name: 'Partnership |||| Partnership',
      fields: {
        customer: 'Cliente',
        provider: 'Fornitore',
        createdAt: 'dal',
      },
      stream: {
        create_customer:
          '%{actor} ha aggiunto un nuovo fornitore |||| %{actor} ha aggiunto %{smart_count} nuovi fornitori',
        create_provider:
          "%{actor} ti ha inserito nell'elenco fornitori della sua U.L. |||| %{actor} ti ha inserito nell'elenco fornitori di %{smart_count} U.L.",
      },
    },
    Customer: {
      name: 'Cliente |||| Clienti',
      fields: {
        name: 'Ragione sociale',
        taxId: 'Partita IVA',
        fiscalCode: 'Codice Fiscale',
        phone: 'Telefono',
        pec: 'Pec',
        sdi: 'Sdi',
        ateco: 'Codice Ateco',
        legalRepresentative: {
          name: 'Legale rappresentante',
          fiscalCode: 'C.F. Legale Rappr.',
        },
      },
    },
    CustomerUnit: {
      name: 'Unità locale |||| Unità locali',
      fields: {
        name: 'Identificativo U.L.',
        email: 'Email',
        organization: 'Ragione sociale',
        phone: 'Telefono',
        pec: 'Pec',
        ateco: 'Codice Ateco',
        rea: 'Numero Rea',
        employeeNumber: 'Numero dipendenti',
        monthsOfBusiness: 'Mesi di attività',
      },
    },
    CustomerUnitSite: {
      name: 'Cantiere |||| Cantieri',
      fields: {
        name: 'Identificativo cantiere',
        activity: 'Attività',
        phone: 'Telefono',
        email: 'Email',
        manager: 'Responsabile',
        localUnit: 'Unità locale collegata',
      },
    },
    Waste: {
      empty: 'Nessun rifiuto trovato',
      name: 'Rifiuto |||| Rifiuti',
      fields: {
        name: 'Descrizione',
        reference: 'Codice interno',
        eer: 'Codice EER',
        physicalState: 'Stato fisico',
        hazardCodes: 'Codici HP',
        hazardPrecaution: 'Assegnata in via cautelativa',
        analysisReference: 'Identificativo analisi',
        analysisExpireOn: 'Scadenza',
        analyzedBy: 'Laboratorio',
        adrTransport: 'Trasporto in ADR',
        adrTransportInfo: 'Riportare frase ADR',
        collectCodes: 'Codice recupero',
        disposalCodes: 'Codice smaltimento',
        packaging: 'Tipo contenitore',
        weight: 'Peso specifico (kg/mc)',
        originDescription:
          'Descrizione del processo che ha generato il rifiuto',
        smell: 'Odore',
        color: 'Colore',
        characteristics: 'Caratteristiche',
        annualProduction: 'Produzione annua',
        collectionFrequency: 'Frequenza ritiro',
        picture: 'Immagine',
        reductionTarget: 'Target',
        economics: {
          collectValue: 'Valore di recupero',
          disposalCost: 'Costo di smaltimento',
          transportCost: 'Costo di trasporto',
        },
        localUnit: 'Unità locale',
        stockQuantity: 'Giacenza (kg)',
        stockVolume: 'Volume (mc)',
      },
    },
    WasteAnalysis: {
      name: 'Rapporto di analisi |||| Rapporti di analisi',
      fields: {
        file: 'File',
        waste: 'Rifiuto',
        archived: 'Archiviato',
        archivedAt: 'Archiviato il',
      },
    },
    WasteAssignment: {
      name: 'Assegnazione |||| Assegnazioni',
      fields: {
        waste: 'Rifiuto',
        type: 'Tipologia',
        provider: 'Fornitore',
        billable: 'Gestore del rifiuto',
      },
      stream: {
        create:
          'Sei stato nominato gestore del rifiuto |||| Sei stato nominato gestore di %{smart_count} rifiuti',
        update:
          'Sei stato nominato gestore del rifiuto |||| Sei stato nominato gestore di %{smart_count} rifiuti',
      },
    },
    WasteOperation: {
      name: 'Operazione |||| Operazioni',
      fields: {
        quantity: 'Quantità (kg)',
        disposedQuantity: 'Peso a destino (kg)',
        residualQuantity: 'Giacenza (kg)',
        reference: 'ID',
        referenceCreatedAt: 'Data',
        protocolReference: 'Nr. Protocollo',
        waste: 'Rifiuto',
        loadOperations: 'Rif. operaz. carico',
        formReference: 'Nr. formulario',
        formCreatedAt: 'Data formulario',
        formReturnedAt: 'Ricezione IV copia',
        driver: 'Autista',
        transportStart: 'Data inizio trasporto',
        transportEnd: 'Data fine trasporto',
        vehiclePlateNumber: 'Targa veicolo',
        trailerPlateNumber: 'Targa rimorchio',
        notes: 'Annotazioni',
        wasteMetadata: {
          name: 'Rifiuto',
          eer: 'Codice EER',
          reference: 'Codice interno',
        },
        siteMetadata: {
          name: 'Prodotto presso',
          activity: 'Attività',
          print: 'Riporta indirizzo e attività nella stampa del registro',
        },
        economics: {
          collectValue: 'Valore di recupero',
          disposalCost: 'Costo di smaltimento',
          transportCost: 'Costo di trasporto',
        },
      },
      filters: {
        action: 'Tipologia',
        status: 'Stato',
        waste: 'Rifiuto',
      },
    },
    WasteOperationTask: {
      name: 'Gestore ambientale |||| Gestori ambientali',
      fields: {
        operation: 'Operazione',
        type: 'Tipologia',
        provider: 'Fornitore',
      },
    },
    WasteOperationRegister: {
      name: 'Registro vidima |||| Registri vidima',
      fields: {
        reference: 'Codice interno',
        'metadata.organization': 'Ditta',
        'metadata.organizationAddress': 'Residenza o domicilio',
        'metadata.fiscalCode': 'Codice fiscale',
        'metadata.siteAddress': "Ubicazione dell'esercizio",
        index: 'Numero di pagine',
        file: 'Registro da vidimare',
        type: 'Attività svolta',
        pages: 'Pagine',
        operations: 'Operazioni',
        localUnit: 'Unità locale',
      },
    },
    WasteOperationRegisterPage: {
      name: 'Pagina registro vidima |||| Pagine registro vidima',
      fields: {
        register: 'Registro',
        index: 'Numero pagina',
        protocolStart: 'Protocollo iniziale',
        protocolEnd: 'Protocollo finale',
      },
    },
    WasteForm: {
      name: 'Formulario |||| Formulari',
      fields: {
        quantity: 'Quantità (kg)',
        disposedQuantity: 'Peso a destino (kg)',
        residualQuantity: 'Giacenza (kg)',
        reference: 'Nr. formulario',
        referenceCreatedAt: 'Data formulario',
        protocolReference: 'Nr. Protocollo',
        waste: 'Rifiuto',
        returnedAt: 'Ricezione IV copia',
        expireAt: 'Data scadenza',
        driver: 'Autista',
        transportStart: 'Data inizio trasporto',
        transportEnd: 'Data fine trasporto',
        vehiclePlateNumber: 'Targa veicolo',
        trailerPlateNumber: 'Targa rimorchio',
        notes: 'Annotazioni',
        wasteMetadata: {
          name: 'Rifiuto',
          eer: 'Codice EER',
          reference: 'Codice interno',
        },
        siteMetadata: {
          name: 'Prodotto presso',
          activity: 'Attività',
          print: 'Riporta indirizzo e attività nella stampa del registro',
        },
      },
      filters: {
        action: 'Tipologia',
        status: 'Stato',
      },
    },
    WasteFormActor: {
      name: 'Gestore ambientale |||| Gestori ambientali',
      fields: {
        form: 'Formulario nr.',
        type: 'Tipologia',
        provider: 'Fornitore',
      },
    },
    Vehicle: {
      name: 'Veicolo |||| Veicoli',
      fields: {
        name: 'Tipo veicolo',
        wasteTypes: 'Rifiuti trasportabili',
        trailer: 'Rimorchio',
        plateNumber: 'Targa',
        localUnit: 'Unità locale',
        authorization: 'Autorizzazione al trasporto',
      },
    },
    ProviderVehicle: {
      name: 'Veicolo |||| Veicoli',
      fields: {
        name: 'Tipo veicolo',
        wasteTypes: 'Rifiuti trasportabili',
        trailer: 'Rimorchio',
        plateNumber: 'Targa',
        localUnit: 'Unità locale',
        authorization: 'Autorizzazione al trasporto',
      },
    },
    ConsultantCustomerUnit: {
      name: 'Unità locale |||| Unità locali',
      fields: {},
      stream: {
        add:
          '%{actor} ti ha nominato consulente per una %{object} |||| %{actor} ti ha nominato consulente per %{smart_count} %{object}',
        remove:
          '%{actor} ha deciso di interrompere il servizio di consulenza per una %{object} |||| %{actor}  ha deciso di interrompere il servizio di consulenza per %{smart_count} %{object}',
      },
    },
    EnvironmentalDeclaration: {
      name: 'Mud |||| Mud',
      fields: {
        year: 'Anno di riferimento',
        localUnit: 'Unità locale',
        cciaa: 'Camera di commercio',
        createdAt: 'Creato il',
        updatedAt: 'Modificato il',
        replacement: 'Sostituisce',
        substitute: 'Sostituto',
        metadata: {
          replace: 'Sostituisce',
          organization: {
            name: 'Ragione sociale',
            fiscalCode: 'Codice fiscale',
            address: 'Indirizzo',
            phone: 'Telefono',
            legalRepresentative: {
              firstName: 'Nome',
              lastName: 'Cognome',
              fiscalCode: 'Codice fiscale',
            },
          },
          localUnit: {
            nome: 'Identificativo U.L.',
            address: 'Indirizzo',
            rea: 'Numero Rea',
            phone: 'Telefono',
            ateco: 'Codice Ateco',
            employeeNumber: 'Totale addetti',
            monthsOfBusiness: 'Mesi di attività',
          },
          wastes: {
            reModule: 'Moduli RE',
            drModule: 'Moduli DR',
            teModule: 'Moduli TE',
          },
        },
      },
    },
    File: {
      name: 'File |||| File',
      stream: {
        ready:
          'Il file %{object_title} è pronto |||| %{smart_count} file sono pronti',
      },
    },
  },
  enums: {
    MembershipRole: {
      ADMIN: 'Amministratore',
      COLLABORATOR: 'Collaboratore',
      CONSULTANT: 'Consulente',
    },
    LocalUnitSiteActivity: {
      ASBESTOS_REMOVAL: 'Bonifica amianto',
      MAINTENANCE: 'Manutenzioni',
      TEMPORARY: 'Cantieri temporanei e mobili',
      HEALTH_CARE: 'Assistenza sanitaria',
    },
    OrganizationType: {
      WASTE_PRODUCING: 'Produttore di rifiuti',
      WASTE_MANAGING: 'Gestore Amb. autorizzato',
      WASTE_CONSULTANCY: 'Consulente ambientale',
    },
    WasteActivity: {
      BROKERAGE: 'Intermediazione',
      DISPOSAL: 'Recupero / smaltimento',
      TRANSPORT: 'Trasporto',
      RECLAMATION: 'Bonifiche',
      OTHER: 'Altre',
    },
    WasteAssignmentType: {
      CARRIER: 'Trasportatore',
      PRIMARY_BROKER: 'Intermediario 1',
      RECEIVER: 'Destinatario',
      SECONDARY_BROKER: 'Intermediario 2',
    },
    WasteOperationAction: {
      LOAD: 'Carico',
      UNLOAD: 'Scarico',
    },
    WasteOperationStatus: {
      DRAFT: 'Non registrato',
      RECORDED: 'Registrato',
      PROTOCOLLED: 'Protocollato',
      PRINTED: 'Stampato',
    },
    WastePhysicalState: {
      AERIFORM: '5 aeriforme',
      VISCOUS_SYRUPY: '6 vischioso sciropposo',
      OTHER: '7 altro',
      LIQUID: '4 liquido',
      SLUDGY: '3 fangoso palabile',
      SOLID: '2 solido non polverulento',
      SOLID_POWDER: '1 solido polverulento',
    },
    WasteType: {
      DANGEROUS: 'Pericolosi',
      NOT_DANGEROUS: 'Non Pericolosi',
    },
    WasteOperationRegisterType: {
      PRODUCTION: 'Produzione',
      TRANSPORT: 'Trasporto',
      RECOVERY: 'Recupero',
      DISPOSAL: 'Smaltimento',
      BROKERAGE: 'Intermediazione e commercio con detenzione',
    },
    StockType: {
      TIME_BASED: 'Temporale',
      VOLUME_BASED: 'Volumetrico',
    },
  },
  message: {
    date: 'Data',
    lastWeek: 'Questa settimana',
    prevWeek: 'Settimana precedente',
    last7Days: 'Ultimi 7 giorni',
    lastMonth: 'Questo mese',
    prevMonth: 'Mese precedente',
    last30Days: 'Ultimi 30 giorni',
    lastYear: 'Anno in corso',
    prevYear: 'Anno precedente',
    address: 'Indirizzo',
    street: 'Indirizzo',
    houseNumber: 'Numero civico',
    city: 'Città',
    zipCode: 'CAP',
    province: 'Provincia',
    countryCode: 'Stato',
    phonePrefix: 'Prefisso',
    phoneNumber: 'Numero',
    downloadFile: 'Scarica file',
    officialDocument: 'Documento ufficiale',
    exportComplete:
      'File in preparazione. Riceverai una notifica quando sarà pronto per essere scaricato.',
    duplicatedUserEmail: 'Questo utente è già presente nel nostro database',
    duplicatedTaxId:
      'Ragione sociale già presente nel database di Rifiutoo. Controlla i dati o contatta l’assistenza Rifiutoo.',
    print: 'Stampa',
    download: 'Scarica',
    uploading: 'Caricamento in corso...',
    assignProtocol: 'Assegna protocollo',
    assignProtocolText:
      "Stai visualizzando i movimenti non protocollati. Seleziona quelli che vuoi protocollare e prosegui con l'assegnazione automatica del numero di protocollo. Ricorda che l’assegnazione del protocollo è irreversibile.",
    printOperations: 'Stampa movimenti',
    printOperationsText:
      'Stai visualizzando i movimenti non stampati. Seleziona quelli che vuoi stampare e prosegui. Ricorda che la stampa dei movimenti è irreversibile.',
    environmentalDeclarationConfirmTitle: 'Scarica MUD',
    environmentalDeclarationConfirmContent:
      'Hai controllato che tutte le informazioni siano corrette? Ricordati che dopo aver generato il file potrai sempre verificarne la sua validità a questo [link] prima di inviarlo alla camera di commercio di riferimento.',
    environmentalDeclarationAuthorFormTitle: 'Scarica MUD',
    environmentalDeclarationAuthorFormContent:
      'Inserisci di seguito le informazioni del dichiarante',
    showArchived: 'Mostra archiviati',
    volumetricStockAlert:
      'Ricorda di impostare il peso specifico nelle singole schede rifiuto così da visualizzare correttamente le giacenze. I volumi vengono calcolati al momento della registrazione del carico, pertanto se cambi il peso specifico solo le nuove giacenze saranno calcolate in funzione del nuovo peso specifico.',
    createStockInfo_line1:
      "Stai creando delle giacenze riferite alla gestione dell'anno precedente. Una giacenza può essere singola (riferita ad un solo movimento di carico) oppure cumulata (riferita a più movimenti di carico) e deve essere creata con data 31/12/Anno precedente. Per agevolarti ti suggeriamo di indicare nel campo annotazioni della giacenza a quali movimenti di carico questa fa riferimento. Es: 88 del 15/06/2022, 96 del 24/10/2022 e 124 del 03/12/2022.",
    createStockInfo_line2:
      "Ti ricordiamo di consolidare tali movimenti (attraverso l'apposito pulsante nella sezione GIACENZA) solo dopo aver caricato tutte le giacenze relative alla gestione dell'anno precedente. Il pulsante CONSOLIDA GIACENZE assegna automaticamente a questi movimenti un numero di protocollo consecutivo tipo G (sezionale) 0001 (protocollo). Le giacenze saranno visibili nel registro di carico e scarico di Rifiutoo estendendo il filtro DATA anche all'anno precedente. Le giacenze non devono essere stampate.",
    createStockInfo_line3:
      "Quando connetti una giacenza/e ad un movimento di scarico ricorda di riportare nel campo annotazioni dello scarico che tale movimento scarica dal registro una giacenza dell'anno precedente. Esempio di annotazione: G0001 = 88 del 15/06/2022, 96 del 24/10/2022 e 124 del 03/12/2022.",
  },
  errors: {
    UNAUTHENTICATED: 'Autenticazione fallita.',
    FORBIDDEN: 'Non hai i permessi per completare questa azione.',
    BAD_USER_INPUT: 'I dati inseriti non sono corretti. Riprova.',
    NOT_FOUND: 'Non trovato.',
    INTERNAL_SERVER_ERROR:
      "Oops! C'è stato un errore. Riprova più tardi o contattaci se il problema persiste.",
    USER_ALREADY_EXIST: 'Questo utente esiste già.',
    DATABASE_ERROR_P2002: 'Record già esistente.',
    DOMAIN_INVALID_REFERENCE_CREATED_AT:
      "La data del movimento è precedente alla data dell'ultimo movimento protocollato",
  },
  stream: {
    actions: {
      ready:
        'Il tuo %{object} è pronto |||| Sono pronti %{smart_count} %{object}',
      create:
        '%{actor} ha creato %{object} |||| %{actor} ha creato %{smart_count} %{object}',
      delete:
        '%{actor} ha cancellato %{object} |||| %{actor} ha cancellato %{smart_count} %{object}',
      add:
        '%{actor} ha aggiunto %{object} |||| %{actor} ha aggiunto %{smart_count} %{object}',
      remove:
        '%{actor} ha rimosso %{object} |||| %{actor} ha rimosso %{smart_count} %{object}',
    },
  },
};

import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {makeStyles} from '@material-ui/core/styles';
import {ACTIONS, SUBJECTS} from 'services/permissions';
import Can from 'components/atoms/Can';

import AdminMenuItems from './AdminMenuItems';
import UserMenuItems from './UserMenuItems';

const useStyles = makeStyles((theme) => ({
  menuItem: {
    textTransform: 'none',
    color: '#2a3b4d',
    backgroundColor: '#fff',
    fontWeight: 500,
    width: '100%',
    justifyContent: 'flex-start',
    padding: 14,
    paddingBottom: 13,
    borderBottom: '1px solid #e6e7e8',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#00b1b0',
    },
    [theme.breakpoints.up('lg')]: {
      padding: 16,
      paddingTop: 20,
      paddingBottom: 20,
      fontSize: 14,
    },
    [theme.breakpoints.up('xl')]: {
      padding: 22,
      fontSize: 16,
    },
    '@media (max-height:720px) and (min-width:1280px)': {
      paddingTop: 12,
      paddingBottom: 12,
    },
  },
  activeMenuItem: {
    color: '#fff',
    backgroundColor: theme.palette.primary.main + '!important',
  },
  subMenuItem: {
    fontWeight: theme.typography.fontWeightMedium,
    color: '#2a3b4d',
    fontSize: 13,
    borderRadius: 3,
    marginBottom: 8,
    '&:last-child': {
      marginBottom: 0,
    },
    '&:hover': {
      color: '#fff',
      backgroundColor: '#2a3b4d',
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: 12,
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: 14,
      marginBottom: 15,
    },
    '@media (max-height:720px) and (min-width:1280px)': {
      marginBottom: 5,
    },
  },
  activeSubMenuItem: {
    backgroundColor: '#2a3b4d',
    color: '#fff',
  },
  menuItemIcon: {
    color: 'inherit',
  },
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const Menu = ({logout, ...others}) => {
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const classes = useStyles();

  return (
    <React.Fragment>
      <Can I={ACTIONS.access} a={SUBJECTS.AdminDashboard} passThrough>
        {(allow) =>
          allow ? (
            <AdminMenuItems {...others} classes={classes} />
          ) : (
            <UserMenuItems {...others} classes={classes} />
          )
        }
      </Can>
      {isXSmall && logout}
    </React.Fragment>
  );
};

export default Menu;

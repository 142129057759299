import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  HttpLink,
} from '@apollo/client';
import config from 'app.config';

import keycloak from './keycloak';

const omitTypename = (key, value) => (key === '__typename' ? undefined : value);

const middlewareLink = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      omitTypename,
    );
  }

  operation.setContext({
    headers: {
      authorization: keycloak.token ? `Bearer ${keycloak.token}` : null,
    },
  });

  return forward(operation);
});

const httpLink = new HttpLink({uri: config('api.url')});

const client = new ApolloClient({
  link: middlewareLink.concat(httpLink),
  cache: new InMemoryCache().restore({}),
});

export default client;

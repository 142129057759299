import React, {useMemo} from 'react';
import moment from 'moment';
import {makeStyles} from '@material-ui/core/styles';
import {useFormState} from 'react-final-form';

import DateInput from './DateInput';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: (props) => (props.inline ? 'row' : 'column'),
  },
  input: (props) => ({
    marginLeft: props.inline ? theme.spacing(2) : undefined,
    '&:first-child': {
      marginLeft: 0,
    },
  }),
}));

export default function DateRangeInput(props) {
  const {sourceStart, sourceEnd, inline, min, max, ...others} = props;
  const classes = useStyles(props);
  const formState = useFormState();

  const minSource = sourceStart || others.source;

  const minEnd = useMemo(() => {
    return formState.values[minSource]
      ? moment(formState.values[minSource]).toDate()
      : undefined;
  }, [formState.values, minSource]);

  return (
    <div className={classes.root}>
      <div className={classes.input}>
        <DateInput
          source={sourceStart}
          min={min}
          max={max}
          label="Da"
          {...others}
        />
      </div>
      <div className={classes.input}>
        <DateInput
          source={sourceEnd}
          min={minEnd}
          max={max}
          label="A"
          {...others}
        />
      </div>
    </div>
  );
}

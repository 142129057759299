import React, {useState} from 'react';
import {MenuItemLink, DashboardMenuItem, getResources} from 'react-admin';
import {useSelector} from 'react-redux';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import ReceiptIcon from '@material-ui/icons/Receipt';
import DefaultIcon from '@material-ui/icons/ViewList';
import PieChartIcon from '@material-ui/icons/PieChart';
import DnsIcon from '@material-ui/icons/Dns';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import SubMenu from 'components/atoms/SubMenu';
import {getLocalUnit, getOrganization} from 'redux/filters';

export default function UserMenuItems({onMenuClick, logout, dense, classes}) {
  const resources = useSelector((state) =>
    getResources(state).reduce((carry, item) => {
      carry[item.name] = item;
      return carry;
    }, {}),
  );

  const [state, setState] = useState({
    menuAnagrafiche: false,
  });

  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const organization = useSelector(getOrganization);
  const localUnit = useSelector(getLocalUnit);

  const handleToggle = (menu) => {
    setState((state) => ({...state, [menu]: !state[menu]}));
  };

  const menuItemProps = {
    onClick: onMenuClick,
    sidebarIsOpen: open,
    className: classes.menuItem,
    classes: {active: classes.activeMenuItem, icon: classes.menuItemIcon},
    dense,
  };

  const subMenuItemProps = {
    ...menuItemProps,
    className: classes.subMenuItem,
    classes: {active: classes.activeSubMenuItem},
  };

  if (!organization) {
    return null;
  }
  return (
    <>
      <DashboardMenuItem
        {...menuItemProps}
        className={classes.menuItem}
        leftIcon={<DashboardOutlinedIcon />}
      />

      {!['WASTE_CONSULTANCY', 'WASTE_MANAGING'].includes(organization.type) && (
        <MenuItemLink
          {...menuItemProps}
          leftIcon={<PieChartIcon />}
          primaryText="Analytics"
          to="/analytics"
        />
      )}

      <SubMenu
        {...menuItemProps}
        name="Anagrafiche"
        icon={<BusinessOutlinedIcon />}
        handleToggle={() => handleToggle('menuAnagrafiche')}
        isOpen={state.menuAnagrafiche}>
        <MenuItemLink
          {...subMenuItemProps}
          primaryText={
            organization.type === 'WASTE_CONSULTANCY'
              ? `Anagrafica azienda`
              : `Anagrafica unità locale`
          }
          to={
            organization.type === 'WASTE_CONSULTANCY'
              ? `/Organization/${organization?.id}`
              : `/LocalUnit/${localUnit?.id}`
          }
        />

        {organization.type !== 'WASTE_CONSULTANCY' && (
          <MenuItemLink
            {...subMenuItemProps}
            primaryText="Fornitori"
            to="/Partnership"
          />
        )}
      </SubMenu>

      {organization.type !== 'WASTE_CONSULTANCY' && (
        <>
          {organization.type !== 'WASTE_MANAGING' && (
            <>
              <MenuItemLink
                {...menuItemProps}
                primaryText="Rifiuti"
                to="/Waste"
                leftIcon={<resources.Waste.icon /> || <DefaultIcon />}
              />
              <SubMenu
                {...menuItemProps}
                name="Registro C/S"
                icon={<resources.WasteOperation.icon /> || <DefaultIcon />}
                handleToggle={() => handleToggle('menuRegistro')}
                isOpen={state.menuRegistro}>
                <MenuItemLink
                  {...subMenuItemProps}
                  primaryText="Movimenti"
                  to="/WasteOperation"
                />
                <MenuItemLink
                  {...subMenuItemProps}
                  primaryText="Registro Vidima"
                  to="/WasteOperationRegister"
                />
              </SubMenu>
            </>
          )}

          <MenuItemLink
            {...menuItemProps}
            primaryText="Formulari"
            to="/WasteForm"
            leftIcon={<resources.WasteForm.icon /> || <DefaultIcon />}
          />

          {organization.type === 'WASTE_PRODUCING' && (
            <MenuItemLink
              {...menuItemProps}
              primaryText="Giacenza"
              to="/stocks"
              leftIcon={<DnsIcon />}
            />
          )}
        </>
      )}

      {organization.type !== 'WASTE_PRODUCING' && (
        <>
          <MenuItemLink
            {...menuItemProps}
            primaryText="Clienti"
            to={
              organization.type === 'WASTE_CONSULTANCY'
                ? '/ConsultantCustomer'
                : '/Customer'
            }
            leftIcon={<resources.Customer.icon /> || <DefaultIcon />}
          />
        </>
      )}

      {['WASTE_PRODUCING', 'WASTE_CONSULTANCY'].includes(organization.type) && (
        <MenuItemLink
          {...menuItemProps}
          primaryText="MUD"
          to="/EnvironmentalDeclaration"
          leftIcon={<ReceiptIcon />}
        />
      )}

      <MenuItemLink
        {...menuItemProps}
        primaryText="Video"
        to="/Video"
        leftIcon={<PlayCircleOutlineIcon />}
      />
    </>
  );
}

import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import {useLocation, useHistory} from 'react-router-dom';
import qs from 'query-string';

const parseFromLocation = (location) => {
  const search = qs.parse(location.search);
  const defaultValue = {organization: null, localUnit: null};

  return (
    search.context?.split(':').reduce((carry, item, index) => {
      if (isEmpty(item)) {
        return carry;
      }

      switch (index) {
        case 0:
          carry.organization = item;
          return carry;
        case 1:
          carry.localUnit = item;
          return carry;
        default:
          return carry;
      }
    }, defaultValue) || defaultValue
  );
};

const addToLocation = (location, context) => {
  return {
    ...location,
    search: qs.stringify({
      ...qs.parse(location.search),
      context: [context.organization, context.localUnit].join(':'),
    }),
  };
};

export default function useGlobalFilterController() {
  const history = useHistory();
  const location = useLocation();
  const localUnits = useSelector(
    (state) => state.admin.resources.LocalUnit?.data || {},
  );
  const [filters, setFilters] = useState(parseFromLocation(location));

  // keeps filters in the URL whenever the location changes
  useEffect(() => {
    const context = parseFromLocation(location);
    if (filters.organization && !isEqual(context, filters)) {
      history.replace(addToLocation(location, filters));
    }
  }, [location, history, filters]);

  useEffect(() => {
    const [resource, resourceId] = location.pathname.substring(1).split('/');

    if (resourceId !== 'create') {
      switch (true) {
        case resourceId && resource === 'Organization':
          setFilters({organization: resourceId, localUnit: null});
          break;
        case resourceId && resource === 'LocalUnit':
          const localUnit = localUnits[resourceId];
          if (localUnit) {
            setFilters({
              organization: localUnit.organization,
              localUnit: localUnit.id,
            });
          }
          break;
        default:
          break;
      }
    }
  }, [location.pathname, setFilters, localUnits]);

  return [filters, setFilters];
}

import React, {useMemo} from 'react';
import {gql} from '@apollo/client';
import {TextInput} from 'react-admin';
import {length} from 'validation';
import apollo from 'services/apollo';

const PARTNERS_COUNT_WHERE_TAX_ID = gql`
  query partnersCountWhereTaxId(
    $input: String!
    $exclude: [PartnerWhereInput!]
  ) {
    partners(
      where: {
        OR: [{taxId: {equals: $input}}, {taxId: {startsWith: $input}}]
        NOT: $exclude
      }
    ) {
      public
      taxId
      name
    }
  }
`;

const validateTaxId = (
  setValidationResult,
  options = {},
  message = 'message.duplicatedTaxId',
) => async (input, otherValues) => {
  if (input?.length >= 11) {
    let exclude = options.exclude;

    if (!exclude && otherValues.id) {
      exclude = {id: {equals: otherValues.id}};
    }

    const {data} = await apollo.query({
      query: PARTNERS_COUNT_WHERE_TAX_ID,
      variables: {input, exclude: exclude ? [exclude] : undefined},
    });

    if (setValidationResult) {
      setValidationResult(data);
    }

    if (data.partners.length) {
      return setValidationResult ? '' : message;
    }
  }
};

export default function TaxIdInput(props) {
  const validate = useMemo(() => {
    const validators =
      typeof props.validate === 'function'
        ? [props.validate]
        : props.validate || [];

    return [length(11), validateTaxId(props.setValidationResult)].concat(
      validators,
    );
  }, [props.validate, props.setValidationResult]);

  return <TextInput {...props} validate={validate} />;
}

import get from 'lodash/get';

export default function createConfig(parse) {
  let context = Object.assign(
    {},
    typeof window !== 'undefined' && window.__RUNTIME_CONFIGURATIONS__,
  );

  if (typeof parse === 'function') {
    context = parse(context) || {};
  }

  return (key) => (key ? get(context, key) : {...context}); // use the spread operator to shallow clone the context
}

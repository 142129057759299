import {useCallback} from 'react';
import {useSelector, shallowEqual} from 'react-redux';
import {useNotify, useDataProvider, useGetManyReference} from 'react-admin';

export default function useLocalUnitSequencesController({record}) {
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const {ids, loading, loaded} = useGetManyReference(
    'LocalUnitSequence',
    'localUnit',
    record?.id,
    {page: 1, perPage: 2},
    {},
  );

  const data = useSelector((state) => {
    return (
      ids?.map((id) => state.admin.resources.LocalUnitSequence.data[id]) || []
    );
  }, shallowEqual);

  const onChange = useCallback(
    (values) => {
      const sequenceId = parseInt(values.id, 10);

      const promise = dataProvider.update('LocalUnitSequence', {
        data: {...values, id: sequenceId},
        id: sequenceId,
      });

      promise.catch((error) =>
        notify(
          typeof error === 'string'
            ? error
            : error.message || 'ra.notification.http_error',
          'warning',
        ),
      );
      return promise;
    },
    [dataProvider, notify],
  );

  return {
    loading,
    loaded,
    data,
    onChange,
  };
}

import React, {useMemo} from 'react';
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  NumberField,
  LinearProgress,
  useTranslate,
} from 'react-admin';
import truncate from 'lodash/truncate';
import {Alert} from '@material-ui/lab';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {useSelector} from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import config from 'app.config';
import {getLocalUnit} from 'redux/filters';
import useVolumeBasedStock from 'hooks/useVolumeBasedStock';
import FormattedNumber from 'components/atoms/FormattedNumber';
import FormattedNumberField from 'components/atoms/FormattedNumberField';
import ChartBar from 'components/atoms/ChartBar';
import ResizableBox from 'components/atoms/ResizableBox';

const useStyles = makeStyles((theme) => ({
  main: {
    flexDirection: 'column-reverse',
    alignItems: 'stretch',
  },
  asideItem: {
    marginBottom: theme.spacing(3),
  },
}));

const getShadesOfColors = (idx) => '#' + (1528 + idx * 10).toString(16);

function VolumetricStocksInnerList({stock, ...others}) {
  const {ids} = others;

  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const wasteColors = useMemo(
    () =>
      ids.reduce((carry, id, index) => {
        carry[id] = getShadesOfColors(index);
        return carry;
      }, {}),
    [ids],
  );

  return (
    <>
      <Datagrid {...others}>
        <TextField source="reference" />
        <TextField source="eer" />
        <TextField source="name" />
        <NumberField source="stockQuantity" />
        <NumberField source="weight" />
        {!isSmall && (
          <FormattedNumberField source="stockVolume" unit="volume" />
        )}
        <FunctionField
          label="Colore sul grafico"
          render={(record) => (
            <div
              style={{
                width: 40,
                height: 20,
                backgroundColor: wasteColors[record.id] || 'inherit',
              }}
            />
          )}
        />
      </Datagrid>
    </>
  );
}

const Aside = ({stock, className, ...others}) => {
  const classes = useStyles();
  const translate = useTranslate();
  const {ids, data, loaded} = others;

  const wasteColors = useMemo(
    () =>
      ids.reduce((carry, id, index) => {
        carry[id] = getShadesOfColors(index);
        return carry;
      }, {}),
    [ids],
  );

  const chartData = useMemo(() => {
    const datums = ids.reduce((carry, id, index) => {
      const waste = data[id];

      if (waste) {
        carry.push({
          primary: truncate(`${waste.eer} - ${waste.name}`, {length: 24}),
          secondary: waste.stockVolume || 0,
          fill: wasteColors[id],
        });
      }

      return carry;
    }, []);

    return [
      {
        label: 'Metri cubi',
        data: datums,
      },
    ];
  }, [data, ids, wasteColors]);
  return (
    <div className={className}>
      <Alert severity="info" className={classes.asideItem}>
        {translate('message.volumetricStockAlert')}
      </Alert>
      <Card className={classes.asideItem}>
        <CardContent>
          <Typography paragraph>
            Parziale rifiuti pericolosi:{' '}
            <FormattedNumber
              unit="volume"
              value={stock.dangerous}
              style={{
                color:
                  stock.dangerous >=
                  config('app.volumetricStockLimits.dangerous')
                    ? 'red'
                    : 'inherit',
              }}
            />
          </Typography>
          <Typography paragraph>
            Totale:{' '}
            <FormattedNumber
              unit="volume"
              value={stock.total}
              style={{
                color:
                  stock.total >= config('app.volumetricStockLimits.total')
                    ? 'red'
                    : 'inherit',
              }}
            />
          </Typography>
          {!loaded ? (
            <LinearProgress />
          ) : (
            <ResizableBox>
              <ChartBar data={chartData} tooltip />
            </ResizableBox>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

function VolumetricStocks() {
  const classes = useStyles();
  const localUnit = useSelector(getLocalUnit);

  const filter = useMemo(() => {
    return localUnit
      ? {
          localUnitId: localUnit.id,
        }
      : undefined;
  }, [localUnit]);

  const {data} = useVolumeBasedStock({filter});

  return (
    <div>
      <Typography variant="h2" style={{margin: '20px 0 -30px 20px'}}>
        Deposito temporaneo - Monitoraggio volumi
      </Typography>
      <List
        classes={{main: classes.main}}
        exporter={false}
        hasCreate={false}
        hasEdit={false}
        hasList={false}
        hasShow={false}
        resource="Waste"
        basePath="/Waste"
        bulkActionButtons={false}
        aside={<Aside stock={data || {}} className={classes.aside} />}
        filter={filter}>
        <VolumetricStocksInnerList stock={data || {}} />
      </List>
    </div>
  );
}

export default VolumetricStocks;

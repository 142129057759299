import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Variation from 'components/organisms/Analytics/Variation';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: 1,
  },
  value: {
    fontWeight: 600,
    marginLeft: 16,
  },
}));

const AnalyticsBox = ({
  title,
  currYear,
  prevYear,
  currValue = 0,
  prevValue = 0,
  unit,
}) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardHeader title={title} />
      <CardContent className={classes.content}>
        <Typography component="div" align="right" variant="subtitle1">
          {currYear}
          <span className={classes.value}>
            {currValue} {unit}
          </span>
        </Typography>
        {prevYear && prevValue ? (
          <Typography component="div" align="right">
            {prevYear}
            <span className={classes.value}>
              {prevValue} {unit}
            </span>
          </Typography>
        ) : (
          ''
        )}
      </CardContent>
      <CardActions>
        {prevValue !== 0 && currValue !== 0 && (
          <Variation
            prevSum={prevValue}
            currSum={currValue}
            color={(v) => (v <= 0 ? 'green' : 'red')}
          />
        )}
      </CardActions>
    </Card>
  );
};

export default AnalyticsBox;

import React from 'react';
import Typography from '@material-ui/core/Typography';
import {formattedNumber} from 'helpers';

export default function FormattedNumber({unit, value, ...others}) {
  const formattedValue = formattedNumber(value, unit);
  let unitLabel = null;

  if (unit === 'volume') {
    unitLabel = 'mc';
  }

  return (
    <Typography component="span" {...others}>
      {formattedValue + (unitLabel ? ' ' + unitLabel : '')}
    </Typography>
  );
}

import React from 'react';
import {Admin as BaseAdmin} from 'react-admin';
import {createBrowserHistory} from 'history';
import useAuthProvider from 'hooks/useAuthProvider';
import useDataProvider from 'hooks/useDataProvider';
import useI18nProvider from 'hooks/useI18nProvider';
import AppLoader from 'components/atoms/AppLoader';

const MemoizedBaseAdmin = React.memo(BaseAdmin);
const history = createBrowserHistory();

export function Admin({children, ...others}) {
  const dataProvider = useDataProvider();
  const authProvider = useAuthProvider();
  const i18nProvider = useI18nProvider();

  if (!dataProvider || !authProvider) {
    return <AppLoader />;
  }

  return (
    <MemoizedBaseAdmin
      disableTelemetry
      history={history}
      dataProvider={dataProvider}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      {...others}>
      {children}
    </MemoizedBaseAdmin>
  );
}

import {useEffect, useState} from 'react';
import {gql} from '@apollo/client';
import buildGraphQLProvider from '@ra-data-prisma/dataprovider';
import apollo from 'services/apollo';
import {HttpError} from 'react-admin';

function throwFormattedError(e) {
  if (e.graphQLErrors && e.graphQLErrors.length) {
    const graphQLError = e.graphQLErrors[0];
    let {code} = graphQLError.extensions;

    if (['DOMAIN', 'DATABASE_ERROR'].includes(code)) {
      code += '_' + graphQLError.message;
    }

    throw new HttpError('errors.' + code, code, graphQLError);
  }
  throw e;
}

function enhanceDataProvider(dataProvider) {
  return new Proxy(dataProvider, {
    apply(target, thisArg, args) {
      const [fetchType, resource, params] = args;

      let filter = params?.filter;

      if (filter) {
        filter = JSON.parse(JSON.stringify(filter), (key, value) => {
          return value === 'NULL' ? null : value;
        });
      }

      return target(fetchType, resource, {...params, filter}).catch(
        throwFormattedError,
      );
    },
  });
}

export default function useDataProvider(config = {}) {
  const [dataProvider, setDataProvider] = useState();

  useEffect(() => {
    buildGraphQLProvider({
      client: apollo,
      resourceViews: {
        LoggedUser: {
          resource: 'User',
          fragment: gql`
            fragment LoggedUser on User {
              id
              firstName
              lastName
              displayName
              email
              phone
              jobTitle
              verified
              enabled
              memberships {
                id
                role
                organization {
                  id
                  type
                  name
                }
                localUnit {
                  id
                  name
                  organization {
                    id
                    type
                    name
                  }
                }
              }
            }
          `,
        },
        ReferenceWasteAssignment: {
          resource: 'WasteAssignment',
          fragment: gql`
            fragment ReferenceWasteAssignment on WasteAssignment {
              id
              waste {
                id
              }
              provider {
                id
                name
                organization {
                  id
                  name
                }
              }
              type
              billable
              createdAt
            }
          `,
        },
        WasteOperationConnection: {
          resource: 'WasteOperationConnection',
          fragment: gql`
            fragment WasteOperationConnection on WasteOperationConnection {
              id
              load {
                id
                action
                status
                reference
                protocolReference
                quantity
                residualQuantity
              }
              unload {
                id
                action
                status
                reference
                protocolReference
                quantity
                disposedQuantity
                residualQuantity
              }
              quantity
            }
          `,
        },
        ReferenceProviderUnit: {
          resource: 'ProviderUnit',
          fragment: gql`
            fragment ReferenceProviderUnit on ProviderUnit {
              id
              name
              address {
                street
                houseNumber
                city
                countryCode
                zipCode
                province
              }
              authorizations {
                id
                number
                activity
                category
                issuedOn
                startFrom
                expireOn
              }
              organization {
                id
                name
                taxId
              }
            }
          `,
        },
        ReferenceCustomerUnit: {
          resource: 'CustomerUnit',
          fragment: gql`
            fragment ReferenceCustomerUnit on CustomerUnit {
              id
              name
              address {
                street
                houseNumber
                city
                countryCode
                zipCode
                province
              }
              organization {
                id
                name
                taxId
              }
            }
          `,
        },
        ConsultantCustomerUnit: {
          resource: 'LocalUnit',
          fragment: gql`
            fragment ConsultantCustomerUnit on LocalUnit {
              id
              name
              address {
                street
                houseNumber
                city
                countryCode
                zipCode
                province
              }
              organization {
                id
                name
                taxId
              }
            }
          `,
        },
        EnvironmentalDeclaration: {
          resource: 'EnvironmentalDeclaration',
          fragment: gql`
            fragment EnvironmentalDeclaration on EnvironmentalDeclaration {
              id
              localUnit {
                id
              }
              cciaa
              year
              createdAt
              updatedAt
              replacement {
                id
              }
              substitute {
                id
              }
              metadata {
                replace
                organization {
                  name
                  taxId
                  address {
                    street
                    houseNumber
                    zipCode
                    istatCode
                  }
                  phone {
                    prefix
                    number
                  }
                  fiscalCode
                  legalRepresentative {
                    firstName
                    lastName
                    fiscalCode
                  }
                }
                localUnit {
                  name
                  rea
                  ateco
                  employeeNumber
                  monthsOfBusiness
                  phone {
                    prefix
                    number
                  }
                  address {
                    street
                    houseNumber
                    zipCode
                    istatCode
                  }
                }
                wastes {
                  eer
                  eerDescription
                  physicalState
                  localUnitProduction {
                    quantity
                    transported
                    stocked
                    collectable
                    disposable
                  }
                  reModule {
                    istatCode
                    activity
                    quantity
                  }
                  teModule {
                    fiscalCode
                    name
                    quantity
                  }
                  drModule {
                    fiscalCode
                    name
                    quantity
                    localUnit
                    address {
                      street
                      houseNumber
                      zipCode
                      istatCode
                    }
                    ceCode
                    disposableQuantity
                    collectableMaterialQuantity
                    collectableEnergyQuantity
                    burnableQuantity
                    dumpableQuantity
                    country
                  }
                }
              }
            }
          `,
        },
      },
    })
      .then(enhanceDataProvider)
      .then((p) => {
        setDataProvider(() => p);
      });
  }, []);

  return dataProvider;
}

import React from 'react';
import {NotificationDropdown as StreamNotificationDropdown} from 'react-activity-feed';
import {makeStyles} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';

import Notification from './Notification';

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: 'initial',
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      top: 10,
      right: 17,
    },
  },
  label: {
    fontSize: '1rem',
    color: theme.palette.text.primary,
    '& .raf-icon-badge': {
      display: 'block',
    },
  },
  icon: {
    display: 'block',
  },
}));

export default function NotificationDropdown({
  onClickNotification,
  formatMessage,
  render,
}) {
  const classes = useStyles();

  return (
    <IconButton
      color="primary"
      classes={{root: classes.root, label: classes.label}}>
      <StreamNotificationDropdown
        right
        notify
        Icon={<NotificationsOutlinedIcon className={classes.icon} />}
        Group={(props) => (
          <Notification
            {...props}
            onClick={onClickNotification}
            formatMessage={formatMessage}
            render={render}
          />
        )}
      />
    </IconButton>
  );
}

import React, {useCallback, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import {
  SimpleForm,
  Toolbar,
  SaveButton,
  Button,
  TextInput,
  required,
} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import {getLocalUnit} from 'redux/filters';

import useLocalUnitSequencesController from 'hooks/useLocalUnitSequencesController';
import useWasteOperationActionsController from 'hooks/useWasteOperationActionsController';

import PositiveNumberInput from 'components/atoms/PositiveNumberInput';
import FormGroup from 'components/atoms/FormGroup';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    justifyContent: 'flex-end',
  },
}));

function StockConsolidateFormToolbar({onCancel, ...others}) {
  return (
    <Toolbar {...others}>
      <Button color="default" label="ra.action.cancel" onClick={onCancel} />
      <SaveButton disabled={others.invalid} />
    </Toolbar>
  );
}

export default function StockConsolidateForm({
  onSuccess = () => {},
  onCancel = () => {},
  initialValues = {operations: []},
}) {
  const referenceLocalUnit = useSelector(getLocalUnit);
  const classes = useStyles();
  const [saving, setSaving] = useState(false);

  const actions = useWasteOperationActionsController();
  const sequences = useLocalUnitSequencesController({
    record: referenceLocalUnit,
  });

  const protocolSequence = useMemo(
    () =>
      sequences.data?.find(
        (item) => item.sequence === 'WASTE_OPERATION_PROTOCOL',
      ),
    [sequences.data],
  );

  const handleAssignProtocol = useCallback(() => {
    return actions.onAssign({
      filter: {
        action: 'LOAD',
        referenceCreatedAt: {
          lt: moment().startOf('year').toISOString(),
        },
      },
    });
  }, [actions]);

  const handleSubmit = useCallback(
    (values) => {
      let promise = null;
      setSaving(true);

      if (
        values.nextValue === sequences.data.nextValue &&
        values.prefix === sequences.data.prefix
      ) {
        promise = handleAssignProtocol(values);
      } else {
        promise = sequences
          .onChange({
            id: protocolSequence?.id,
            nextValue: values.nextValue,
            prefix: values.prefix,
          })
          .then(() => handleAssignProtocol(values));
      }

      return promise
        .then((response) => {
          onSuccess(response);
        })
        .finally(() => setSaving(false));
    },
    [sequences, handleAssignProtocol, onSuccess, protocolSequence],
  );

  if (!protocolSequence) {
    return (
      <CardContent>
        <CircularProgress />
      </CardContent>
    );
  }

  return (
    <SimpleForm
      saving={saving}
      record={{
        operations: initialValues.operations,
        nextValue: protocolSequence.nextValue,
        prefix: isEmpty(protocolSequence.prefix)
          ? 'G'
          : protocolSequence.prefix,
      }}
      save={handleSubmit}
      toolbar={
        <StockConsolidateFormToolbar
          className={classes.toolbar}
          onCancel={onCancel}
        />
      }>
      <FormGroup>
        <TextInput
          source="prefix"
          label="Sezionale"
          style={{
            width: 'auto',
          }}
        />
        <PositiveNumberInput
          fullWidth
          source="nextValue"
          validate={required()}
          label="Numero di protocollo"
        />
      </FormGroup>
    </SimpleForm>
  );
}

import React, {useEffect, useMemo, useState, useCallback} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import moment from 'moment';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  FormWithRedirect,
  ReferenceInput,
  SelectInput,
  FunctionField,
  ListActions,
} from 'react-admin';
import {VictoryAxis, VictoryBar, VictoryChart, VictoryTheme} from 'victory';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {useSelector} from 'react-redux';
import {getLocalUnit} from 'redux/filters';
import {
  formattedNumber,
  formattedWasteOperationReference,
  formattedWasteTitle,
} from 'helpers';
import {gql, useQuery} from '@apollo/client';

const useStyles = makeStyles((theme) => ({
  actions: {
    marginBottom: theme.spacing(2),
    alignSelf: 'flex-end',
  },
  filters: {
    flex: 1,
  },
  input: {
    minWidth: 240,
  },
  aside: {
    marginRight: theme.spacing(4),
    order: -1,
    // width: '60%',
  },
}));

const TOATALS_QUERY = gql`
  query timedStocksAggregate(
    $expiredLt: DateTime!
    $rem60DaysGt: DateTime!
    $waste: WasteWhereInput
  ) {
    expired: wasteOperationAggregate(
      where: {action: LOAD, referenceCreatedAt: {lt: $expiredLt}, waste: $waste}
    ) {
      sum {
        residualQuantity
      }
    }

    remaining30Days: wasteOperationAggregate(
      where: {
        action: LOAD
        referenceCreatedAt: {lt: $rem60DaysGt, gt: $expiredLt}
        waste: $waste
      }
    ) {
      sum {
        residualQuantity
      }
    }

    remaining60Days: wasteOperationAggregate(
      where: {
        action: LOAD
        referenceCreatedAt: {gt: $rem60DaysGt}
        waste: $waste
      }
    ) {
      sum {
        residualQuantity
      }
    }
  }
`;

const TimedStockFiltersForm = ({className, ...others}) => {
  const classes = useStyles();
  const localUnit = useSelector(getLocalUnit);

  return (
    <FormWithRedirect
      {...others}
      render={({handleSubmitWithRedirect, pristine, saving}) => (
        <div className={className}>
          <Typography variant="overline" component="h4">
            Filtri
          </Typography>
          <ReferenceInput
            resettable
            source="waste"
            reference="Waste"
            label="Rifiuto"
            filter={{localUnitId: localUnit.id}}
            className={classes.input}
            onChange={handleSubmitWithRedirect}>
            <SelectInput optionText={formattedWasteTitle} />
          </ReferenceInput>
        </div>
      )}
    />
  );
};

const StockExpirationBarChart = ({filter, ...others}) => {
  const defaultFilter = useMemo(() => {
    return {
      expiredLt: moment().subtract(60, 'days').toISOString(),
      rem60DaysGt: moment().subtract(30, 'days').toISOString(),
    };
  }, []);

  const {data} = useQuery(TOATALS_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      ...defaultFilter,
      waste: filter.waste,
    },
  });

  const chartData = useMemo(
    () => [
      {
        x: 1,
        y: data?.remaining60Days?.sum?.residualQuantity || 0,
      },
      {
        x: 2,
        y: data?.remaining30Days?.sum?.residualQuantity || 0,
      },
      {
        x: 3,
        y: data?.expired?.sum?.residualQuantity || 0,
      },
    ],
    [data],
  );

  const ColorFor30DaysMax = '#78D19E';
  const ColorFor58DaysMax = '#EBB64D';
  const ColorForMoreThan59Days = '#EE7070';

  return (
    <VictoryChart
      {...others}
      theme={VictoryTheme.material}
      padding={{right: 60}}
      domainPadding={{x: [100, 100]}}>
      <VictoryAxis tickValues={['< 60 gg', '< 30 gg', 'scaduto']} />
      <VictoryBar
        horizontal
        labels={({datum}) => Math.round(datum.y) + ' kg'}
        animate={{
          duration: 2000,
          onLoad: {duration: 500},
        }}
        style={{
          data: {
            fill: ({datum}) =>
              datum.x === 1
                ? ColorFor30DaysMax
                : datum.x === 2
                ? ColorFor58DaysMax
                : ColorForMoreThan59Days,
          },
        }}
        data={chartData}
      />
    </VictoryChart>
  );
};

const TimedStocksAside = ({filter}) => {
  const classes = useStyles();

  return (
    <div className={classes.aside}>
      <Card>
        <CardContent>
          <StockExpirationBarChart filter={filter} width={560} />
        </CardContent>
      </Card>
    </div>
  );
};

const TimedStocksListActions = ({save, ...others}) => {
  const classes = useStyles();
  return (
    <>
      <TimedStockFiltersForm save={save} className={classes.filters} />
      <ListActions {...others} className={classes.actions} />
    </>
  );
};

const TimedStocks = (props) => {
  const localUnit = useSelector(getLocalUnit);

  const version = useMemo(() => localUnit.id || 0, [localUnit]);

  const defaultWasteFilter = useMemo(
    () => (localUnit ? {localUnitId: {equals: localUnit.id}} : {}),
    [localUnit],
  );

  const [filter, setFilter] = useState({
    referenceCreatedAt: {lte: moment().endOf('day').toISOString()},
    residualQuantity: {gt: 0},
    action: 'LOAD',
  });

  const handleFilter = useCallback(
    ({waste}) => {
      setFilter((state) => ({
        ...state,
        waste: !waste ? defaultWasteFilter : {id: {equals: waste}},
      }));
    },
    [defaultWasteFilter],
  );

  // sets/resets the default waste filter if changes
  useEffect(() => {
    if (defaultWasteFilter) {
      setFilter((state) => ({...state, waste: defaultWasteFilter}));
    }
  }, [defaultWasteFilter]);

  return (
    <>
      <List
        exporter={false}
        filter={filter}
        resource="WasteOperation"
        basePath="/WasteOperation"
        sort={{}}
        actions={<TimedStocksListActions save={handleFilter} key={version} />}
        bulkActionButtons={false}
        aside={<TimedStocksAside filter={filter} />}>
        <Datagrid>
          <FunctionField
            source="reference"
            render={formattedWasteOperationReference}
          />
          <DateField source="referenceCreatedAt" />
          <TextField source="protocolReference" />

          <ReferenceField source="waste" reference="Waste">
            <FunctionField
              render={(record) =>
                `${record.eer} - ${record.name} - ${record.reference}`
              }
            />
          </ReferenceField>
          <FunctionField
            source="quantity"
            textAlign="right"
            render={(record) => {
              return (
                <span>
                  {formattedNumber(record.quantity)}{' '}
                  {record.action === 'LOAD' &&
                    record.residualQuantity > 0 &&
                    record.residualQuantity !== record.quantity && (
                      <span>({formattedNumber(record.residualQuantity)})</span>
                    )}
                </span>
              );
            }}
          />
          <FunctionField
            label="gg. alla scadenza"
            render={(record) => {
              const daysPassed = moment().diff(
                record.referenceCreatedAt,
                'days',
              );
              const remainingDays = 90 - daysPassed;

              return remainingDays > 0 ? remainingDays : 'Scaduto';
            }}
          />
        </Datagrid>
      </List>
    </>
  );
};

export default TimedStocks;

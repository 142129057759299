import React, {useCallback, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import {Button} from 'react-admin';

import StockConsolidateForm from './StockConsolidateForm';

const useStyles = makeStyles((theme) => ({
  dialogSubtitle: {
    paddingBottom: 0,
    marginBottom: 0,
  },
}));

function StockConsolidateDialog({
  initialValues,
  onSuccess,
  onClose,
  ...others
}) {
  const classes = useStyles();

  return (
    <Dialog {...others} onClose={onClose}>
      <DialogTitle>Numero di protocollo</DialogTitle>
      <DialogContent className={classes.dialogSubtitle}>
        <DialogContentText paragraph={false}>
          Scegli il numero di protocollo da cui partire per la numerazione.
        </DialogContentText>
      </DialogContent>
      <StockConsolidateForm
        initialValues={initialValues}
        onCancel={onClose}
        onSuccess={onSuccess}
      />
    </Dialog>
  );
}

export default function StocksConsolidateButton({
  onSuccess = () => {},
  onCancel = () => {},
  disabled,
  label = 'message.assignProtocol',
  selectedIds,
}) {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
    onCancel();
  }, [onCancel]);

  return (
    <>
      <Button
        label={label}
        onClick={handleOpen}
        color="secondary"
        disabled={disabled}
      />
      <StockConsolidateDialog
        open={open}
        onClose={handleClose}
        initialValues={{operations: selectedIds}}
        onSuccess={onSuccess}
      />
    </>
  );
}

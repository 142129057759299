import React from 'react';
import {useAuthState} from 'react-admin';
import {useQuery, gql} from '@apollo/client';
import {StreamApp, Streami18n} from 'react-activity-feed';
import 'react-activity-feed/dist/index.css';

import config from 'app.config';

const STREAM_QUERY = gql`
  {
    stream {
      token
    }
  }
`;

const i18n = new Streami18n({language: 'it'});

class StreamContextProvider extends StreamApp {
  async componentDidMount() {
    const {i18nInstance} = this.props;
    let streami18n;

    if (i18nInstance && i18nInstance instanceof Streami18n) {
      streami18n = i18nInstance;
    } else {
      streami18n = new Streami18n({language: 'en'});
    }

    streami18n.registerSetLanguageCallback((t) => {
      this.setState({t});
    });

    const {t, tDateTimeParser} = await streami18n.getTranslators();
    this.setState({t, tDateTimeParser});
  }

  static initClientState(props, state) {
    if (props.token) {
      return StreamApp.initClientState(props, state);
    }

    return state;
  }
}

const streamInitClientState = StreamApp.initClientState;
StreamApp.initClientState = (props, state) =>
  props.token
    ? streamInitClientState(props, state)
    : {...state, client: {}, user: {}, userData: {}};

export default function StreamProvider({children}) {
  const {authenticated} = useAuthState();

  const {data} = useQuery(STREAM_QUERY, {
    skip: !authenticated,
  });

  const child = React.Children.only(children);

  return (
    <StreamContextProvider
      apiKey={config('stream.key')}
      appId={config('stream.app')}
      token={data?.stream?.token}
      i18nInstance={i18n}>
      {child}
    </StreamContextProvider>
  );
}

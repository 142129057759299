import React from 'react';
import BaseAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import {makeStyles} from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import {toggleSidebar} from 'ra-core';
import {useDispatch} from 'react-redux';
import {ACTIONS, SUBJECTS} from 'services/permissions';
import useHomePath from 'hooks/useHomePath';
import Can from 'components/atoms/Can';
import Link from 'components/atoms/Link';
import Logo from 'components/atoms/Logo';

import OrganizationFilter from './OrganizationFilter';
import LocalUnitFilter from './LocalUnitFilter';
import ProfileMenu from './ProfileMenu';
import NotificationHub from './NotificationHub';

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: '#fff',
    borderBottom: '1px solid #e6e7e8',
    boxShadow: 'none',
  },
  bar: {
    justifyContent: 'center',
    flexWrap: 'wrap',
    minHeight: 74,
    paddingLeft: 18,
    paddingRight: 18,
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start',
      flexWrap: 'nowrap',
    },
    [theme.breakpoints.up('xl')]: {
      paddingLeft: 24,
      paddingRight: 24,
    },
  },
  leftContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      justifyContent: 'space-between',
    },
  },
  menuIcon: {
    marginRight: 20,
    position: 'absolute',
    top: 12,
    left: 18,
    [theme.breakpoints.up('md')]: {
      position: 'relative',
    },
    [theme.breakpoints.up('lg')]: {
      display: (props) => (!props.open ? 'block' : 'none'),
    },
  },
}));

const AppBar = (props) => {
  const classes = useStyles(props);
  const homePath = useHomePath();
  const dispatch = useDispatch();

  return (
    <BaseAppBar {...props} className={classes.header}>
      <Toolbar className={classes.bar}>
        <IconButton
          aria-label="open menu"
          className={classes.menuIcon}
          onClick={() => dispatch(toggleSidebar())}>
          <MenuIcon />
        </IconButton>

        <Link to={homePath || '#'} style={{padding: 10, marginLeft: -10}}>
          <Logo />
        </Link>

        <div className={classes.leftContainer}>
          <NotificationHub />
          <Can not I={ACTIONS.access} a={SUBJECTS.AdminDashboard}>
            {() => (
              <>
                <LocalUnitFilter />
                <OrganizationFilter />
              </>
            )}
          </Can>
          <ProfileMenu />
        </div>
      </Toolbar>
    </BaseAppBar>
  );
};

export default AppBar;

import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {fade} from '@material-ui/core/styles/colorManipulator';
import ActionDelete from '@material-ui/icons/Delete';
import classnames from 'classnames';
import inflection from 'inflection';
import {useTranslate, Confirm, Button} from 'react-admin';
import useArchiveWithConfirmController from 'hooks/useArchiveWithConfirmController';

const defaultIcon = <ActionDelete />;

const useStyles = makeStyles(
  (theme) => ({
    deleteButton: {
      color: theme.palette.error.main,
      '&:hover': {
        backgroundColor: fade(theme.palette.error.main, 0.12),
        // Reset on mouse devices
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    },
  }),
  {name: 'RaDeleteWithConfirmButton'},
);

const ArchiveButton = (props) => {
  const {
    classes: classesOverride,
    className,
    confirmTitle = 'ra.message.archive_title',
    confirmContent = 'ra.message.archive_content',
    icon = defaultIcon,
    label = 'ra.action.archive',
    onClick,
    record,
    resource,
    redirectParams = ['list', props.basePath],
    ...rest
  } = props;
  const translate = useTranslate();
  const classes = useStyles(props);
  const {
    open,
    loading,
    handleDialogOpen,
    handleDialogClose,
    handleArchive,
  } = useArchiveWithConfirmController({
    resource,
    record,
    redirectParams,
    onClick,
  });
  return (
    <>
      <Button
        onClick={handleDialogOpen}
        label={label}
        className={classnames(
          'ra-delete-button',
          classes.deleteButton,
          className,
        )}
        key="button"
        {...rest}>
        {icon}
      </Button>
      <Confirm
        isOpen={open}
        loading={loading}
        title={confirmTitle}
        content={confirmContent}
        translateOptions={{
          name: translate(`resources.${resource}.forcedCaseName`, {
            smart_count: 1,
            _: inflection.humanize(
              translate(`resources.${resource}.name`, {
                smart_count: 1,
                _: inflection.singularize(resource),
              }),
              true,
            ),
          }),
          id: record?.id,
        }}
        onConfirm={handleArchive}
        onClose={handleDialogClose}
      />
    </>
  );
};

export default React.memo(ArchiveButton);

import React from 'react';
import {Link} from 'react-router-dom';
import {Button, useRefresh} from 'react-admin';
import useStocksAlertController from 'hooks/useStocksAlertController';
import TopAlert from 'components/atoms/TopAlert';
import StocksConsolidateButton from './StocksConsolidateButton';

export default function StocksWelcomeAlert() {
  const refresh = useRefresh();
  const {loaded, show} = useStocksAlertController();

  return loaded && show ? (
    <TopAlert severity="warning" title="Ricordati le giacenze">
      <p>
        Se hai già un registro cartaceo o prodotto con altro software, devi
        importare su Rifiutoo le tue giacenze.
      </p>
      <Button
        variant="contained"
        color="primary"
        label="Crea giacenza"
        component={Link}
        to="/WasteOperation/create?action=load&stock=1"
      />
      <StocksConsolidateButton
        color="primary"
        label="Consolida giacenze"
        component={Link}
        onSuccess={() => refresh()}
        to="/stocks"
      />
    </TopAlert>
  ) : null;
}

import React, {useEffect, useCallback, useState, useMemo} from 'react';
import {Link} from 'react-router-dom';
import {useSelector, shallowEqual} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuList from '@material-ui/core/MenuList';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {useRedirect, useGetList} from 'react-admin';
import useGlobalFilterContext from 'hooks/useGlobalFilterContext';

const useStyles = makeStyles((theme) => ({
  formControl: {
    flexDirection: 'row',
    alignItems: 'center',
    margin: theme.spacing(1),
  },
  label: {
    flex: '0 0 auto',
    position: 'relative',
  },
  select: {
    marginTop: '0px !important',
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  nestedMenu: {
    borderTop: '1px solid',
    borderColor: theme.palette.grey[300],
  },
  buttonLabel: {
    textTransform: 'initial',
  },
}));

function OrganizationFilter() {
  const classes = useStyles();
  const redirect = useRedirect();
  const [anchorEl, setAnchorEl] = useState(null);
  // const {identity} = useGetIdentity();
  const [filters, setFilters] = useGlobalFilterContext();

  const {ids, loading, error} = useGetList(
    'Organization',
    {
      page: 1,
      perPage: 100,
    },
    {
      field: 'type',
      order: 'DESC',
    },
    // {
    //   memberships: identity && {some: {user: {id: {equals: identity.id}}}},
    // },
    {
      onFailure: () => {},
    },
  );

  const data = useSelector(
    (state) =>
      state.admin.resources.Organization
        ? state.admin.resources.Organization.data
        : null,
    shallowEqual,
  );

  const choices = useMemo(() => {
    const choiceIds = ids && ids.length ? ids : Object.keys(data);

    return (
      data &&
      choiceIds &&
      choiceIds
        .map((id) => data[id])
        .filter((org) => org.id !== filters.organization)
    );
  }, [data, ids, filters.organization]);

  const value = useMemo(() => {
    return data && data[filters.organization];
  }, [filters.organization, data]);

  const handleChange = useCallback(
    (choice) => {
      setAnchorEl(null);
      redirect(`/Organization/${choice.id}`);
    },
    [redirect],
  );

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  // sets the first option as default value if necessary
  useEffect(() => {
    if (choices?.length && !filters.organization) {
      const firstChoice = choices[0];
      setFilters({organization: firstChoice.id, localUnit: null});
    }
  }, [choices, filters, setFilters]);

  if (loading || error) {
    return <CircularProgress size={25} />;
  }

  return !value ? null : (
    <>
      <Button
        classes={{label: classes.buttonLabel}}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}>
        {value.name}
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          subheader: (
            <ListSubheader disableSticky>
              <Typography variant="caption" component="span">
                {value.name}
              </Typography>
            </ListSubheader>
          ),
        }}>
        <MenuItem
          onClick={handleClose}
          component={Link}
          to={`/Organization/${value.id}?showHelper=true`}>
          Anagrafica azienda
        </MenuItem>
        {choices?.length > 0 && (
          <MenuList
            component="div"
            className={classes.nestedMenu}
            subheader={
              <ListSubheader disableSticky>
                <Typography variant="caption" component="span">
                  Altre ragioni sociali
                </Typography>
              </ListSubheader>
            }>
            {choices.map((choice) => (
              <MenuItem
                key={choice.id}
                component={Link}
                to={`/Organization/${choice.id}?showHelper=true`}
                onClick={() => handleChange(choice)}>
                <ListItemText primary={choice.name} />
                <ArrowForwardIcon fontSize="small" />
              </MenuItem>
            ))}
          </MenuList>
        )}
      </Menu>
    </>
  );
}

export default React.memo(OrganizationFilter);

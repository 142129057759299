import React, {useMemo} from 'react';
import isArray from 'lodash/isArray';
import {NumberInput, minValue as validateMinValue} from 'react-admin';

function PositiveNumberInput({includeZero, ...others}) {
  const minValue = useMemo(() => (includeZero ? 0 : 1), [includeZero]);

  const validate = useMemo(() => {
    let validators = [validateMinValue(minValue)];

    if (isArray(others.validate)) {
      validators = validators.concat(others.validate);
    } else if (others.validate) {
      validators.push(others.validate);
    }
    return validators;
  }, [others.validate, minValue]);

  return <NumberInput {...others} min={minValue} validate={validate} />;
}

PositiveNumberInput.defaultValue = {
  addLabel: true,
};

export default PositiveNumberInput;

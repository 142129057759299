import React from 'react';
import {getLocalUnit} from 'redux/filters';
import {useSelector} from 'react-redux';

import VolumetricStocks from './VolumetricStocks';
import TimedStocks from './TimedStocks';
import StocksWelcomeAlert from './StocksWelcomeAlert';

function Stocks(props) {
  const localUnit = useSelector(getLocalUnit);
  let content = null;
  console.log(localUnit?.settings?.stockType);
  switch (localUnit?.settings?.stockType) {
    case 'VOLUME_BASED':
      content = <VolumetricStocks {...props} />;
      break;
    case 'TIME_BASED':
      content = <TimedStocks {...props} />;
      break;
  }

  return (
    <>
      <StocksWelcomeAlert />
      {content}
    </>
  );
}

export default Stocks;

import moment from 'moment';
import {getLocalUnit, getOrganization} from 'redux/filters';
import {useQueryWithStore} from 'react-admin';
import {useSelector} from 'react-redux';

export default function useStocksAlertController() {
  const localUnit = useSelector(getLocalUnit);
  const organization = useSelector(getOrganization);

  const filter = {
    OR: [
      {
        status: 'PROTOCOLLED',
        referenceCreatedAt: {gte: moment().startOf('year').toISOString()},
      },
      {
        status: 'PRINTED',
      },
    ],
  };

  if (localUnit) {
    filter.waste = {localUnitId: {equals: localUnit.id}};
  } else if (organization) {
    filter.waste = {localUnitId: {organizationId: {equals: organization.id}}};
  }

  const {loading, loaded, total} = useQueryWithStore({
    type: 'getList',
    resource: 'WasteOperation',
    payload: {
      filter: filter,
      pagination: {page: 1, perPage: 1},
      sort: {field: 'createdAt', order: 'DESC'},
    },
  });

  return {loading, loaded, show: total === 0};
}

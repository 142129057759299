import React from 'react';
import get from 'lodash/get';
import {FunctionField} from 'react-admin';
import {formattedNumber} from 'helpers';

export default function FormattedNumberField({unit, ...others}) {
  return (
    <FunctionField
      {...others}
      render={(record) => {
        const value = get(record, others.source);
        return formattedNumber(value, unit);
      }}
    />
  );
}

FormattedNumberField.defaultProps = {
  textAlign: 'right',
  addLabel: true,
};

import React, {useEffect, useMemo, useRef, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import config from 'app.config';

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = {current: null};
const placeService = {current: null};

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

const PlacesAutocomplete = ({onSet, country = 'it'}) => {
  const classes = useStyles();
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const loaded = useRef(false);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${config(
          'gMap',
        )}&libraries=places`,
        document.querySelector('head'),
        'google-maps',
      );
    }

    loaded.current = true;
  }

  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 500),
    [],
  );

  useEffect(() => {
    let active = true;

    if (
      !autocompleteService.current &&
      !placeService.current &&
      window.google
    ) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
      placeService.current = new window.google.maps.places.PlacesService(
        document.createElement('div'),
      );
    }
    if (!autocompleteService.current || !placeService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch(
      {
        input: inputValue,
        types: ['address'],
        componentRestrictions: {
          country: country.toUpperCase(),
        },
      },
      (results) => {
        if (active) {
          let newOptions = [];

          if (value) {
            newOptions = [value];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
        }
      },
    );

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch, country]);

  return (
    <Autocomplete
      fullWidth
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);

        if (newValue?.place_id) {
          placeService.current.getDetails(
            {placeId: newValue.place_id},
            (data) => {
              const splitAddr = data.address_components.reduce(
                (acc, c) => {
                  if (c.types.includes('street_number')) {
                    acc.houseNumber = c.short_name;
                  }
                  if (c.types.includes('route')) {
                    acc.street += c.short_name;
                  }
                  // if (c.types.includes('locality')) {
                  //   acc.street += ` ${c.short_name}`;
                  // }
                  if (c.types.includes('administrative_area_level_3')) {
                    acc.city += ` ${c.short_name}`;
                  }
                  if (c.types.includes('administrative_area_level_2')) {
                    acc.province += ` ${c.short_name}`;
                  }
                  if (c.types.includes('postal_code')) {
                    acc.zipCode += ` ${c.short_name}`;
                  }

                  return acc;
                },
                {
                  street: '',
                  houseNumber: '',
                  zipCode: '',
                  city: '',
                  province: '',
                },
              );

              onSet(splitAddr);
            },
          );
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          inputProps={{
            style: {backgroundColor: 'transparent', ...params.inputProps.style},
            ...params.inputProps,
            autocomplete: 'chrome-off', // see https://stackoverflow.com/a/30976223/1820242
          }}
          label="Cerca un indirizzo..."
          fullWidth
        />
      )}
      renderOption={(option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length]),
        );

        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{fontWeight: part.highlight ? 700 : 400}}>
                  {part.text}
                </span>
              ))}

              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

export default PlacesAutocomplete;

import React, {useEffect, useCallback, useMemo} from 'react';
import {useSelector, shallowEqual} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  useQueryWithStore,
  CRUD_GET_LIST,
  useVersion,
  useRedirect,
} from 'react-admin';
import useGlobalFilterContext from 'hooks/useGlobalFilterContext';

const useStyles = makeStyles((theme) => ({
  formControl: {
    flexDirection: 'row',
    alignItems: 'center',
    margin: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      marginLeft: 0,
    },
  },
  label: {
    flex: '0 0 auto',
    position: 'relative',
  },
  select: {
    minWidth: 120,
    marginTop: '0px !important',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function LocalUnitFilter() {
  const classes = useStyles();
  const location = useLocation();
  const redirect = useRedirect();
  const version = useVersion();
  const [filters, setFilters] = useGlobalFilterContext();

  const isOrganizationPage = useMemo(
    () => location.pathname.includes('/Organization', 0),
    [location.pathname],
  );

  const isLocalUnitPage = useMemo(
    () => location.pathname.includes('/LocalUnit', 0),
    [location.pathname],
  );

  const {data: ids, loading} = useQueryWithStore(
    {
      type: 'getList',
      resource: 'LocalUnit',
      payload: {
        pagination: {
          page: 1,
          perPage: 100,
        },
        sort: {
          field: 'name',
          order: 'ASC',
        },
        filter: {},
      },
    },
    {
      action: CRUD_GET_LIST,
      onFailure: () => {},
      version,
    },
    (state) =>
      state.admin.resources.LocalUnit
        ? Object.keys(state.admin.resources.LocalUnit.data)
        : null,
    (state) =>
      state.admin.resources.LocalUnit
        ? Object.keys(state.admin.resources.LocalUnit.data).length
        : null,
  );

  const data = useSelector(
    (state) =>
      state.admin.resources.LocalUnit
        ? state.admin.resources.LocalUnit.data
        : null,
    shallowEqual,
  );

  const choices = useMemo(() => {
    return (
      data &&
      ids &&
      ids
        .map((id) => data[id])
        .filter(
          (lU) =>
            lU?.organization === filters?.organization &&
            lU?.archived === false,
        )
    );
  }, [data, ids, filters.organization]);

  const handleChange = useCallback(
    (event) => {
      const value = choices.find((lU) => lU.id === event.target.value);

      if (isLocalUnitPage) {
        redirect('edit', '/LocalUnit', value.id);
      } else {
        setFilters({
          localUnit: value.id,
          organization: value.organization,
        });
      }
    },
    [choices, setFilters, isLocalUnitPage, redirect],
  );

  // sets the first option as default value if necessary
  useEffect(() => {
    if (choices?.length && !filters.localUnit && !isOrganizationPage) {
      const firstChoice = choices[0];
      setFilters({
        localUnit: firstChoice.id,
        organization: firstChoice.organization,
      });
    }
  }, [filters.localUnit, setFilters, choices, isOrganizationPage]);

  if (loading) {
    return <CircularProgress size={25} />;
  }

  if (!choices?.length || isOrganizationPage) {
    return null;
  }

  return (
    <FormControl className={classes.formControl}>
      <InputLabel className={classes.label}>Unità locale</InputLabel>
      <Select
        className={classes.select}
        value={filters.localUnit || ''}
        onChange={handleChange}>
        {choices.map((lU) => (
          <MenuItem key={lU.id} value={lU.id}>
            {lU.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default React.memo(LocalUnitFilter);

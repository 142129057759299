import React from 'react';
import {useListContext} from 'react-admin';
import {withStyles} from '@material-ui/core/styles';

const DatagridActions = withStyles({
  root: {
    alignItems: 'center',
    justifyContent: 'flex-end',
    display: 'flex',
  },
})(
  (
  {classes, children, textAlign, ...props}, //eslint-disable-line
  ) => {
    const listContext = useListContext();

    if (listContext.selectedIds && listContext.selectedIds.length) {
      return null;
    }

    return (
      <div
        className={classes.root}
        onClick={(e) => {
          e.stopPropagation();
        }}>
        {React.Children.map(
          children,
          (button) => button && React.cloneElement(button, props),
        )}
      </div>
    );
  },
);

DatagridActions.defaultProps = {
  textAlign: 'right',
};

export default DatagridActions;

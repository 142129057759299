import React, {useEffect, useMemo, useCallback} from 'react';
import inflection from 'inflection';
import {useTranslate} from 'react-admin';
import useNotification from 'hooks/useNotification';

import NotificationListItem from './NotificationListItem';

function Notification(props) {
  const {
    className,
    activityGroup,
    onClick = () => {},
    onMarkAsRead,
    render,
  } = props;

  const notification = useNotification(props);

  const translate = useTranslate();

  const headerText = useMemo(() => {
    const {actor, verb, data, resource, context} = notification;

    const actorText =
      actor?.id === 'system' ? 'Rifiutoo' : actor?.displayName || actor?.id;

    const mainObject = Object.keys((data && data[0]) || {}).reduce(
      (carry, key) => {
        carry['object_' + key] = data[0][key];
        return carry;
      },
      {},
    );

    const localeContext = {
      ...mainObject,
      verb,
      actor: actorText,
      object: inflection.humanize(
        translate('resources.' + resource + '.name', {
          smart_count: data?.length || 1,
          _: resource,
        }),
        true,
      ),
      smart_count: data?.length || 1,
      context: context.localUnit?.name || context.organization?.name,
    };

    return translate('resources.' + resource + '.stream.' + verb, {
      ...localeContext,
      _: translate('stream.actions.' + verb, localeContext),
    });
  }, [translate, notification]);

  const handleClick = useCallback(() => {
    return onClick(notification);
  }, [onClick, notification]);

  useEffect(() => {
    if (!activityGroup.is_read) {
      onMarkAsRead(activityGroup);
    }
  }, [activityGroup, onMarkAsRead]);

  const renderProps = {
    className,
    notification,
    headerText,
    onClick: handleClick,
  };

  return typeof render === 'function' ? (
    render(renderProps)
  ) : (
    <NotificationListItem {...renderProps} />
  );
}

export default React.memo(Notification);

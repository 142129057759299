import React, {useMemo} from 'react';
import {
  ResettableTextField,
  FieldTitle,
  InputHelperText,
  useInput,
} from 'react-admin';

import FormGroup from './FormGroup';

export default function PhoneNumberTextInput(props) {
  const {
    label,
    format,
    className,
    helperText,
    onBlur,
    onChange,
    onFocus,
    options,
    parse,
    resource,
    source,
    validate,
    ...rest
  } = props;

  const {
    id,
    input,
    isRequired,
    meta: {error, submitError, touched},
  } = useInput({
    format,
    onBlur,
    onChange,
    onFocus,
    parse,
    resource,
    source,
    type: 'text',
    validate,
    ...rest,
  });

  const value = useMemo(() => {
    if (input.value) {
      const [prefix, number] = input.value.split('-');
      return {prefix, number};
    }
    return {prefix: '', number: ''};
  }, [input.value]);

  const handleChangePrefix = (e) => {
    e.target.value = input.onChange([e.target.value, value.number].join('-'));
    return e;
  };

  const handleChangeNumber = (e) => {
    e.target.value = input.onChange([value.prefix, e.target.value].join('-'));
    return e;
  };

  return (
    <FormGroup id={id} className={className}>
      <ResettableTextField
        {...input}
        value={value.prefix}
        label={
          label && (
            <FieldTitle
              label={label.prefix}
              source={source}
              resource={resource}
              isRequired={isRequired}
            />
          )
        }
        onChange={handleChangePrefix}
        error={!!(touched && (error || submitError))}
        helperText={
          <InputHelperText
            touched={touched}
            error={error || submitError}
            helperText={helperText}
          />
        }
        {...options}
      />
      <ResettableTextField
        {...input}
        label={
          label && (
            <FieldTitle
              label={label.number}
              source={source}
              resource={resource}
              isRequired={isRequired}
            />
          )
        }
        value={value.number}
        onChange={handleChangeNumber}
        error={!!(touched && (error || submitError))}
        helperText={
          <InputHelperText
            touched={touched}
            error={error || submitError}
            helperText={helperText}
          />
        }
        {...options}
      />
    </FormGroup>
  );
}

PhoneNumberTextInput.defaultProps = {
  addLabel: false,
  label: {
    prefix: 'message.phonePrefix',
    number: 'message.phoneNumber',
  },
};

import * as React from 'react';

function Logo(props) {
  return (
    <svg
      width={156}
      height={48}
      viewBox="0 0 1071 417"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeMiterlimit="10"
      {...props}>
      <path
        d="M12.688 85.927c-6.165 0-11.159 4.997-11.159 11.163v222.447c0 6.162 4.994 11.159 11.159 11.159h222.447c6.163 0 11.163-4.997 11.163-11.159V97.09c0-6.166-5-11.163-11.163-11.163H12.688Z"
        fill="url(#a)"
        fillRule="nonzero"
      />
      <path
        d="M374.572 206.925c9.706 0 17.203-2.1 22.478-6.3 5.275-4.197 7.925-10.212 7.925-18.044 0-7.256-2.569-12.881-7.706-16.868-5.138-3.982-12.494-5.978-22.06-5.978h-27.615v47.19h26.978Zm-26.978 80.928h-28.25V135.391h58.225c9.844 0 18.047.965 24.619 2.884 6.568 1.922 12.203 5.091 16.912 9.503 4.847 4.272 8.672 9.647 11.459 16.122 2.775 6.478 4.169 13.206 4.169 20.178 0 9.819-2.428 18.15-7.275 24.982-4.859 6.831-12.634 12.671-23.334 17.512l33.606 61.281h-31.894l-29.975-56.584h-28.262v56.584ZM482.463 287.852H456.56V179.593h25.903v108.259Zm0-125.768H456.56v-26.691h25.903v26.691ZM568.513 197.959h-23.972v89.893h-25.903v-89.893h-16.485v-18.366h16.485v-17.297c0-10.247 2.49-17.541 7.487-21.884 4.997-4.341 13.419-6.516 25.266-6.516 3.134 0 5.893.075 8.24.216 2.35.143 5.319.5 8.882 1.065v22.422c-2.429-.281-4.282-.462-5.566-.531a62.375 62.375 0 0 0-3.631-.109c-9.857 0-14.775 4.268-14.775 12.815v9.819h23.972v18.366ZM614.326 287.852h-25.903V179.593h25.903v108.259Zm0-125.768h-25.903v-26.691h25.903v26.691ZM665.911 179.594v62.562c0 8.544 1.494 14.735 4.5 18.579 2.997 3.843 7.703 5.762 14.125 5.762 7.844 0 13.947-2.912 18.306-8.753 4.341-5.831 6.531-13.881 6.531-24.128v-54.022h25.904v108.259h-25.904v-10.675c-8.559 9.11-19.412 13.663-32.54 13.663-6.563 0-12.594-1.247-18.091-3.738-5.494-2.487-9.675-5.868-12.522-10.14-2.29-3.132-3.893-6.903-4.818-11.319-.935-4.409-1.394-10.247-1.394-17.506v-68.544h25.903ZM816.608 197.959h-20.966v54.45c0 5.121.916 8.753 2.778 10.887 1.85 2.134 4.988 3.203 9.419 3.203 2 0 4.706-.215 8.131-.64l2.569-.216v23.062c-4.003.566-7.137.963-9.419 1.179-2.287.212-4.778.315-7.484.315-12.284 0-20.916-2.631-25.903-7.897-1.294-1.281-2.328-2.631-3.106-4.056-.797-1.425-1.394-3.169-1.822-5.234-.428-2.06-.716-4.553-.857-7.472-.15-2.919-.209-6.513-.209-10.785v-56.796h-14.991v-18.366h14.991v-38.65h25.903v38.65h20.966v18.366Z"
        fill="#293b4d"
        fillRule="nonzero"
        stroke="#293b4d"
        strokeWidth=".31"
      />
      <path
        d="M864.769 208.42c-4.846 6.121-7.265 14.521-7.265 25.196s2.419 19.075 7.265 25.197c4.86 6.122 11.569 9.178 20.129 9.178 8.562 0 15.271-3.056 20.128-9.178 4.84-6.122 7.268-14.522 7.268-25.197 0-10.675-2.428-19.075-7.268-25.196-4.857-6.116-11.566-9.182-20.128-9.182-8.56 0-15.269 3.066-20.129 9.182m42.507-29.254c6.772 2.85 12.543 6.872 17.331 12.066 4.778 5.197 8.531 11.388 11.241 18.578 2.706 7.188 4.062 15.122 4.062 23.806 0 8.685-1.356 16.622-4.062 23.807-2.71 7.193-6.463 13.384-11.241 18.578-4.788 5.197-10.559 9.219-17.331 12.065-6.788 2.844-14.244 4.269-22.378 4.269-8.132 0-15.588-1.425-22.369-4.269-6.778-2.846-12.563-6.868-17.341-12.065-4.787-5.194-8.531-11.385-11.237-18.578-2.71-7.185-4.063-15.122-4.063-23.807 0-8.684 1.353-16.618 4.063-23.806 2.706-7.19 6.45-13.381 11.237-18.578 4.778-5.194 10.563-9.216 17.341-12.066 6.781-2.843 14.237-4.268 22.369-4.268 8.134 0 15.59 1.425 22.378 4.268"
        fill="#293b4d"
        fillRule="nonzero"
      />
      <path
        d="M864.769 208.42c-4.846 6.121-7.265 14.521-7.265 25.196s2.419 19.075 7.265 25.197c4.86 6.122 11.569 9.178 20.129 9.178 8.562 0 15.271-3.056 20.128-9.178 4.84-6.122 7.268-14.522 7.268-25.197 0-10.675-2.428-19.075-7.268-25.196-4.857-6.116-11.566-9.182-20.128-9.182-8.56 0-15.269 3.066-20.129 9.182Zm42.507-29.254c6.772 2.85 12.543 6.872 17.331 12.066 4.778 5.197 8.531 11.388 11.241 18.578 2.706 7.188 4.062 15.122 4.062 23.806 0 8.685-1.356 16.622-4.062 23.807-2.71 7.193-6.463 13.384-11.241 18.578-4.788 5.197-10.559 9.219-17.331 12.065-6.788 2.844-14.244 4.269-22.378 4.269-8.132 0-15.588-1.425-22.369-4.269-6.778-2.846-12.563-6.868-17.341-12.065-4.787-5.194-8.531-11.385-11.237-18.578-2.71-7.185-4.063-15.122-4.063-23.807 0-8.684 1.353-16.618 4.063-23.806 2.706-7.19 6.45-13.381 11.237-18.578 4.778-5.194 10.563-9.216 17.341-12.066 6.781-2.843 14.237-4.268 22.369-4.268 8.134 0 15.59 1.425 22.378 4.268Z"
        fill="none"
        stroke="#293b4d"
        strokeWidth=".31"
      />
      <path
        d="M992.789 208.42c-4.859 6.121-7.278 14.521-7.278 25.196s2.419 19.075 7.278 25.197 11.569 9.178 20.128 9.178c8.563 0 15.272-3.056 20.119-9.178 4.85-6.122 7.278-14.522 7.278-25.197 0-10.675-2.428-19.075-7.278-25.196-4.847-6.116-11.556-9.182-20.119-9.182-8.559 0-15.269 3.066-20.128 9.182m42.497-29.254c6.778 2.85 12.553 6.872 17.331 12.066 4.778 5.197 8.531 11.388 11.25 18.578 2.706 7.188 4.063 15.122 4.063 23.806 0 8.685-1.357 16.622-4.063 23.807-2.719 7.193-6.472 13.384-11.25 18.578-4.778 5.197-10.553 9.219-17.331 12.065-6.778 2.844-14.244 4.269-22.369 4.269-8.144 0-15.6-1.425-22.378-4.269-6.769-2.846-12.562-6.868-17.341-12.065-4.778-5.194-8.521-11.385-11.228-18.578-2.718-7.185-4.072-15.122-4.072-23.807 0-8.684 1.354-16.618 4.072-23.806 2.707-7.19 6.45-13.381 11.228-18.578 4.779-5.194 10.572-9.216 17.341-12.066 6.778-2.843 14.234-4.268 22.378-4.268 8.125 0 15.591 1.425 22.369 4.268"
        fill="#293b4d"
        fillRule="nonzero"
      />
      <path
        d="M992.789 208.42c-4.859 6.121-7.278 14.521-7.278 25.196s2.419 19.075 7.278 25.197 11.569 9.178 20.128 9.178c8.563 0 15.272-3.056 20.119-9.178 4.85-6.122 7.278-14.522 7.278-25.197 0-10.675-2.428-19.075-7.278-25.196-4.847-6.116-11.556-9.182-20.119-9.182-8.559 0-15.269 3.066-20.128 9.182Zm42.497-29.254c6.778 2.85 12.553 6.872 17.331 12.066 4.778 5.197 8.531 11.388 11.25 18.578 2.706 7.188 4.063 15.122 4.063 23.806 0 8.685-1.357 16.622-4.063 23.807-2.719 7.193-6.472 13.384-11.25 18.578-4.778 5.197-10.553 9.219-17.331 12.065-6.778 2.844-14.244 4.269-22.369 4.269-8.144 0-15.6-1.425-22.378-4.269-6.769-2.846-12.562-6.868-17.341-12.065-4.778-5.194-8.521-11.385-11.228-18.578-2.718-7.185-4.072-15.122-4.072-23.807 0-8.684 1.354-16.618 4.072-23.806 2.707-7.19 6.45-13.381 11.228-18.578 4.779-5.194 10.572-9.216 17.341-12.066 6.778-2.843 14.234-4.268 22.378-4.268 8.125 0 15.591 1.425 22.369 4.268Z"
        fill="none"
        stroke="#293b4d"
        strokeWidth=".31"
      />
      <path
        d="M108.965 235.86a69.16 69.16 0 0 1 7.253 1.684 69.72 69.72 0 0 1 20.869 10.088c3.741 2.656 7.053 5.756 10.163 9.037 17.872 32.706 3.1 59.919-9.994 74.028h109.041v-53.368c-10.175-54.81-49.985-102.147-107.057-118.81-3.062-.89-6.109-1.462-9.087-1.975l6.719-25.159-110.204 33.881 74.863 98.434 7.434-27.84Z"
        fill="#fff"
        fillRule="nonzero"
      />
      <defs>
        <linearGradient
          id="a"
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(33.158 -269.477 250.39) scale(341.612)">
          <stop offset="0" stopColor="#5ec6c8" />
          <stop offset=".46" stopColor="#0cb2b2" />
          <stop offset="1" stopColor="#108f8b" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Logo;

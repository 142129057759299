import React, {useMemo, useState, useEffect, useCallback} from 'react';
import {useDataProvider, useGetIdentity} from 'react-admin';
import defineAbilityFor from 'services/permissions';

export const Context = React.createContext();

function PermissionsContext({children}) {
  const {identity} = useGetIdentity();
  const dataProvider = useDataProvider();

  const [loggedUser, setLoggedUser] = useState();
  const [state, setState] = useState({
    loaded: false,
    loading: true,
    permissions: defineAbilityFor(),
  });

  const fetchData = useCallback(() => {
    return dataProvider
      .getOne('LoggedUser', {id: identity.id})
      .then((response) => {
        if (response?.data) {
          setLoggedUser(response.data);
        }
      })
      .catch((error) => {
        setState({
          loading: false,
          loaded: true,
          error,
          permissions: defineAbilityFor(),
        });
      });
  }, [dataProvider, identity]);

  const contextValue = useMemo(
    () => ({
      ...state,
      refetch: () => {
        setState((v) => ({...v, loading: true}));
        fetchData();
      },
    }),
    [state, fetchData],
  );

  useEffect(() => {
    if (dataProvider && identity) {
      fetchData();
    }
  }, [fetchData, dataProvider, identity]);

  useEffect(() => {
    if (loggedUser) {
      setState({
        loading: false,
        loaded: true,
        permissions: defineAbilityFor(loggedUser, identity.roles),
      });
    }
  }, [loggedUser, identity]);

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
}

export default React.memo(PermissionsContext);

import React from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import MuiFormGroup from '@material-ui/core/FormGroup';

const useStyles = makeStyles((theme) => ({
  root: {},
  row: {
    width: '100%',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',

    '&>*': {
      width: '100%',
      marginRight: theme.spacing(2),

      '&:last-child': {
        marginRight: 0,
      },
    },
  },
}));

export default function FormGroup(props) {
  const classes = useStyles(props);
  const {children, className, formClassName, row = true, ...others} = props;

  return (
    <MuiFormGroup classes={{root: classes.root, row: classes.row}} row={row}>
      {React.Children.toArray(children).map((child) => {
        return React.cloneElement(child, {
          ...others,
          className: clsx(className, classes.input),
        });
      })}
    </MuiFormGroup>
  );
}

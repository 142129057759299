import React from 'react';
import {ThemeProvider} from '@material-ui/core/styles';
import theme from 'theme';

import Layout from './Layout';

const BaseLayout = ({children}) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default Layout(BaseLayout);

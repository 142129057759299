import {useCallback} from 'react';
import {useNotify} from 'react-admin';
import {useMutation, gql} from '@apollo/client';

const ASSIGN_WASTE_OPERATION_PROTOCOL = gql`
  mutation wasteOperationAssignProtocol($where: WasteOperationWhereInput!) {
    assignManyWasteOperationProtocol(where: $where) {
      id
      status
      protocolReference
      protocolCreatedAt
    }
  }
`;

const PRINT_WASTE_OPERATIONS = gql`
  mutation printWasteOperations(
    $operations: [WasteOperationWhereUniqueInput!]!
    $where: WasteOperationRegisterWhereUniqueInput!
  ) {
    updateOneWasteOperationRegister(
      where: $where
      data: {operations: {connect: $operations}}
    ) {
      id
      pages {
        id
        index
        protocolStart
        protocolEnd
      }
    }
  }
`;

export default function useWasteOperationActionsController() {
  const notify = useNotify();

  const handleError = useCallback(
    (error) => {
      notify(
        typeof error === 'string'
          ? error
          : error.message || 'ra.notification.http_error',
        'warning',
      );
    },
    [notify],
  );

  const [assignProtocol] = useMutation(ASSIGN_WASTE_OPERATION_PROTOCOL, {
    onCompleted: () => {
      notify('Movimenti protocollati');
    },
    onError: handleError,
  });

  const onAssign = useCallback(
    ({filter}) => {
      return assignProtocol({variables: {where: filter}});
    },
    [assignProtocol],
  );

  const [printOperations] = useMutation(PRINT_WASTE_OPERATIONS, {
    onCompleted: () => {
      notify('Preparazione stampa avviata');
    },
    onError: handleError,
  });

  const onPrint = useCallback(
    ({ids, target}) =>
      printOperations({
        variables: {
          where: {id: target},
          operations: ids.map((id) => ({id})),
        },
      }).then(({data}) => {
        return data.updateOneWasteOperationRegister;
      }),
    [printOperations],
  );

  return {
    onAssign,
    onPrint,
  };
}

import React, {useMemo} from 'react';
import {VictoryPie, VictoryLabel} from 'victory';
import {useTheme} from '@material-ui/core/styles';

function DestinationPie({data}) {
  const theme = useTheme();
  const chartData = useMemo(() => {
    const value = [];

    if (!data) {
      return value;
    }

    const aggregatedData = data.reduce(
      (carry, item) => {
        if (item.collectCode) {
          carry.collected += item.disposedQuantity || 0;
        } else if (item.disposalCode) {
          carry.disposed += item.disposedQuantity || 0;
        }

        return carry;
      },
      {collected: 0, disposed: 0},
    );

    const totalDisposedQuantity =
      aggregatedData.disposed + aggregatedData.collected;
   
    return [
      {
        x: 'Smaltimento',
        y: aggregatedData.disposed / totalDisposedQuantity,
      },
      {
        x: 'Recupero',
        y: aggregatedData.collected / totalDisposedQuantity,
      },
    ];
  }, [data]);

  return (
    <VictoryPie
      labels={({datum}) => `${datum.x}: ${parseFloat(datum.y * 100).toFixed(2)}%`}
      padding={{left: 120, bottom: 0, top: 0, right: 120}}
      colorScale={[theme.palette.error.light, theme.palette.success.light]}
      data={chartData}
      labelComponent={<VictoryLabel renderInPortal />}
      style={{labels: {fill: 'black', fontSize: 14}}}
    />
  );
}

export default DestinationPie;

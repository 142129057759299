import React from 'react';
import {getComponentDisplayName} from 'helpers';

import PermissionsContext from './PermissionsContext';

export default function Layout(Component) {
  const EnhancedLayout = (props) => {
    return (
      <PermissionsContext>
        <Component {...props} />
      </PermissionsContext>
    );
  };

  EnhancedLayout.displayName = `Layout(${getComponentDisplayName(Component)})`;

  return React.memo(EnhancedLayout);
}

import React, {useCallback} from 'react';
import get from 'lodash/get';
import {TextInput} from 'react-admin';
import FormGroup from 'components/atoms/FormGroup';
import CountryInput from 'components/atoms/CountryInput';
import PlacesAutocomplete from 'components/organisms/PlacesAutocomplete';
import {useForm, useFormState} from 'react-final-form';

const AddressInput = ({
  source,
  label = {},
  initialValue = {},
  id = source,
  addLabel, //eslint-disable-line
  showAutocomplete,
  ...others
}) => {
  const {change} = useForm();

  const {values: formValues} = useFormState({
    subscription: {
      values: true,
    },
  });

  const setProvidedAddr = useCallback(
    (splitAddr) => {
      change(source, {
        ...get(formValues, source),
        ...splitAddr,
      });
    },
    [formValues, source, change],
  );

  return (
    <>
      {others.disabled !== true && showAutocomplete && (
        <FormGroup>
          <PlacesAutocomplete
            onSet={setProvidedAddr}
            country={
              get(formValues, source + '.countryCode') ||
              initialValue.countryCode ||
              'it'
            }
          />
        </FormGroup>
      )}
      <FormGroup>
        <TextInput
          {...others}
          id={id + '.street'}
          source={source + '.street'}
          label={label.street || 'message.street'}
          initialValue={initialValue.street}
          inputProps={{autocomplete: 'chrome-off'}}
        />
        <TextInput
          {...others}
          id={id + '.houseNumber'}
          source={source + '.houseNumber'}
          label={label.houseNumber || 'message.houseNumber'}
          initialValue={initialValue.houseNumber}
          inputProps={{autocomplete: 'chrome-off'}}
        />
      </FormGroup>

      <FormGroup>
        <TextInput
          {...others}
          id={id + '.city'}
          source={source + '.city'}
          label={label.city || 'message.city'}
          initialValue={initialValue.city}
          inputProps={{autocomplete: 'chrome-off'}}
        />
        <CountryInput
          {...others}
          id={id + '.countryCode'}
          source={source + '.countryCode'}
          label={label.city || 'message.countryCode'}
          initialValue={initialValue.countryCode || 'it'}
        />
      </FormGroup>

      <FormGroup>
        <TextInput
          {...others}
          id={id + '.province'}
          source={source + '.province'}
          label={label.city || 'message.province'}
          initialValue={initialValue.city}
          inputProps={{autocomplete: 'chrome-off', maxlength: 2}}
          helperText="In sigla (es: RM)"
        />
        <TextInput
          {...others}
          id={id + '.zipCode'}
          source={source + '.zipCode'}
          label={label.zipCode || 'message.zipCode'}
          initialValue={initialValue.zipCode}
          inputProps={{autocomplete: 'chrome-off'}}
        />
      </FormGroup>
    </>
  );
};

AddressInput.defaultProps = {
  addLabel: false,
};

export default React.memo(AddressInput);

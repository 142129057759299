import React, {useCallback} from 'react';
import inflection from 'inflection';
import {useRedirect, useDataProvider, useTranslate} from 'react-admin';
import CircularProgress from '@material-ui/core/CircularProgress';
import useStream from 'hooks/useStream';
import useHomePath from 'hooks/useHomePath';
import NotificationDropdown from 'components/atoms/NotificationDropdown';
import NotificationListItem from 'components/atoms/NotificationListItem';

function NotificationHub() {
  const [
    stream, //eslint-disable-line
    loading,
  ] = useStream();

  const translate = useTranslate();
  const homePath = useHomePath();
  const redirect = useRedirect();

  const dataProvider = useDataProvider();

  const handleClickNotification = useCallback(
    ({verb, resource, data, context}) => {
      if (verb !== 'delete') {
        const mainObject = data && data[0];

        switch (resource) {
          case 'File':
            dataProvider
              .getOne('File', {id: mainObject?.id})
              .then((response) => {
                window.open(response.data.url);
              });
            break;
          case 'Membership':
            let basePath = '/Organization';
            let id = mainObject.organization;

            if (mainObject.localUnit) {
              basePath = '/LocalUnit';
              id = mainObject.localUnit;
            }

            redirect('edit', basePath, id);
            break;
          case 'Partnership':
            if (context.localUnit && mainObject) {
              if (context.localUnit.id === mainObject.customer) {
                redirect('list', '/' + resource);
              } else {
                redirect('list', '/Customer');
              }
            }
            break;
          case 'WasteAssignment':
            redirect('list', '/Customer');
            break;
          case 'UserInvite':
            redirect(homePath);
            break;
          default:
            redirect('list', '/' + resource);
            break;
        }
      }
    },
    [redirect, dataProvider, homePath],
  );

  const handleRender = useCallback(
    (props) => {
      let {notification, headerText} = props;
      const {
        actor,
        verb,
        data,
        resource,
        context,
        origin,
        loading,
      } = notification;

      const actorText =
        actor?.id === 'system' ? 'Rifiutoo' : actor?.displayName || actor?.id;

      if (
        notification.resource === 'Membership' &&
        origin.includes('notification')
      ) {
        headerText = translate(
          'resources.Membership.stream.' + verb + '_user',
          {
            verb,
            actor: actorText,
            object: inflection.humanize(
              translate('resources.' + resource + '.name', {
                smart_count: data?.length || 1,
                _: resource,
              }),
              true,
            ),
            smart_count: data?.length || 1,
            context: context.localUnit?.name || context.organization?.name,
            _: headerText,
          },
        );
      }

      if (
        resource === 'Partnership' &&
        context.localUnit &&
        !loading &&
        data.length
      ) {
        console.log(data)
        let isCustomer = false
        if (data[0]) {
          isCustomer = context.localUnit.id === data[0].customer;
        }
        

        headerText = translate(
          'resources.Partnership.stream.' +
            verb +
            '_' +
            (isCustomer ? 'customer' : 'provider'),
          {
            verb,
            actor: actorText,
            object: inflection.humanize(
              translate('resources.' + resource + '.name', {
                smart_count: data?.length || 1,
                _: resource,
              }),
              true,
            ),
            smart_count: data?.length || 1,
            context: context.localUnit?.name || context.organization?.name,
            _: headerText,
          },
        );
      }

      return <NotificationListItem {...props} headerText={headerText} />;
    },
    [translate],
  );

  return loading ? (
    <CircularProgress />
  ) : (
    <NotificationDropdown
      onClickNotification={handleClickNotification}
      render={handleRender}
    />
  );
}

export default React.memo(NotificationHub);

import React from 'react';
import {Can as BaseCan} from '@casl/react';
import CircularProgress from '@material-ui/core/CircularProgress';
import usePermissions from 'hooks/usePermissions';

export default function Can(props) {
  const {loaded, permissions} = usePermissions();

  return !loaded ? (
    <CircularProgress />
  ) : (
    <BaseCan {...props} ability={permissions} />
  );
}

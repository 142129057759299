import React from 'react';
import {Layout as RaLayout} from 'react-admin';
import {Redirect} from 'react-router-dom';
import usePermissions from 'hooks/usePermissions';
import theme from 'theme';
import {ACTIONS, SUBJECTS} from 'services/permissions';
import AppLoader from 'components/atoms/AppLoader';

import Menu from './Menu/Menu';
import AppBar from './AppBar/AppBar';
import Sidebar from './Sidebar/Sidebar';
import GlobalFilterContext from './GlobalFilterContext';
import StreamProvider from './StreamProvider';
import Layout from './Layout';

const AdminLayout = (props) => {
  const {loaded, loading, permissions} = usePermissions();

  if (!loaded) {
    return <AppLoader />;
  }

  if (!loading && permissions.cannot(ACTIONS.read, SUBJECTS.Organization)) {
    return <Redirect to="/onboarding" />;
  }

  return (
    <StreamProvider>
      <GlobalFilterContext>
        <RaLayout
          {...props}
          theme={theme}
          sidebar={Sidebar}
          menu={Menu}
          appBar={AppBar}
        />
      </GlobalFilterContext>
    </StreamProvider>
  );
};

export default Layout(AdminLayout);

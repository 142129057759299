import moment from 'moment';
import {useMemo, useRef} from 'react';
import {useGetMany} from 'react-admin';

function useNotificationActor({activityGroup}) {
  const latestActivity = useMemo(() => activityGroup.activities[0], [
    activityGroup.activities,
  ]);

  const users = useGetMany('PartnerMember', [latestActivity.actor.id]);

  const actor = useMemo(() => {
    const activityActor = latestActivity.actor;

    if (activityActor === 'system' || activityActor.id === 'system') {
      return {id: 'system'};
    }

    if (!users.data || !users.data.length || users.loading) {
      return activityActor;
    }

    return (
      users.data.find((user) => user && user.id === activityActor.id) ||
      activityActor
    );
  }, [users.loading, users.data, latestActivity.actor]);

  return {loading: users.loading, data: actor};
}

function useNotificationObjects({activityGroup}) {
  const [resource, resourceIds] = useMemo(
    () =>
      activityGroup.activities.reduce(
        (carry, activity) => {
          const [object, objectId] = activity.object.split(':');
          carry[0] = object;
          carry[1].push(objectId);

          return carry;
        },
        [null, []],
      ),
    [activityGroup.activities],
  );

  const objects = useGetMany(resource, resourceIds);

  return objects;
}

function useNotificationContext({activityGroup}) {
  const latestActivity = useMemo(() => activityGroup.activities[0], [
    activityGroup.activities,
  ]);

  const [organizationId, localUnitId] = useMemo(
    () => latestActivity.context?.split(':') || [],
    [latestActivity.context],
  );

  const organizations = useGetMany('Partner', [organizationId]);
  const localUnits = useGetMany('PartnerUnit', [localUnitId]);

  return {
    loading: organizations.loading || localUnits.loading,
    organization:
      organizations.data &&
      organizations.data.find((node) => node && node.id === organizationId),
    localUnit:
      localUnits.data &&
      localUnits.data.find((node) => node && node.id === localUnitId),
  };
}

export default function useNotification(props) {
  const {activityGroup} = props;

  const isRead = useRef(activityGroup.is_read);
  const actor = useNotificationActor(props);
  const objects = useNotificationObjects(props);
  const context = useNotificationContext(props);

  const notification = useMemo(() => {
    const latestActivity = activityGroup.activities[0];
    return {
      loading: actor.loading || objects.loading || context.loading,
      data: objects.data,
      actor: actor.data,
      origin: latestActivity.origin || latestActivity._origin,
      isRead: isRead.current,
      resource: latestActivity.object.split(':')[0],
      verb: latestActivity.verb,
      context: {
        organization: context.organization,
        localUnit: context.localUnit,
      },
      time: moment.utc(latestActivity.time).local().toDate(),
    };
  }, [isRead, actor, objects, activityGroup.activities, context]);

  return notification;
}

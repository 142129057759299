import React, {useMemo} from 'react';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import {DateInput as BaseDateInput} from 'react-admin';

const toDateString = (dateTime) =>
  dateTime && moment(dateTime).format('YYYY-MM-DD');

const toInputValue = (v) => {
  if (isEmpty(v)) {
    return v;
  }

  const value = moment.utc(v);

  return value.isValid() ? value.local().format('YYYY-MM-DD') : v;
};

const fromInputValue = (v) => {
  const value = moment(v, 'YYYY-MM-DD');

  return value.isValid() ? value.startOf('day').toISOString() : v;
};

function DateInput({min, max, ...others}) {
  const inputOptions = useMemo(
    () => ({
      format: 'dd/MM/yyyy',
      inputProps: {
        min: toDateString(min),
        max: toDateString(max),
      },
    }),
    [min, max],
  );

  return (
    <BaseDateInput
      options={inputOptions}
      format={toInputValue}
      parse={fromInputValue}
      {...others}
    />
  );
}

export default React.memo(DateInput);

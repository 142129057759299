import React, {useMemo} from 'react';
import {gql} from '@apollo/client';
import {TextInput, email} from 'react-admin';
import apollo from 'services/apollo';

const PARTNER_MEMBERS_COUNT_WHERE_EMAIL = gql`
  query partnerMembersCountWhereEmail(
    $input: String!
    $exclude: [PartnerMemberWhereInput!]
  ) {
    count: partnerMembersCount(
      where: {OR: [{email: {equals: $input}}], NOT: $exclude}
    )
  }
`;

const validatePartnerMemberEmail = (
  options = {},
  message = 'message.duplicatedUserEmail',
) => async (input, otherValues) => {
  if (input?.length >= 5) {
    let exclude = options.exclude;

    if (!exclude && otherValues.id) {
      exclude = {id: {equals: otherValues.id}};
    }

    const {data} = await apollo.query({
      query: PARTNER_MEMBERS_COUNT_WHERE_EMAIL,
      variables: {input, exclude: exclude ? [exclude] : undefined},
    });

    if (data.count) {
      return message;
    }
  }
};

export default function EmailInput({unique, ...others}) {
  const validate = useMemo(() => {
    const validators = [email()].concat(
      typeof others.validate === 'function'
        ? [others.validate]
        : others.validate || [],
    );

    if (unique) {
      validators.push(validatePartnerMemberEmail());
    }

    return validators;
  }, [others.validate, unique]);

  return <TextInput {...others} type="email" validate={validate} />;
}

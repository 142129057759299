import {useMemo} from 'react';
import round from 'lodash/round';
import {gql, useQuery} from '@apollo/client';

const STOCKS_TOTAL_QUERY = gql`
  query StocksTotalQuery($localUnitId: StringNullableFilter) {
    dangerous: wasteOperationAggregate(
      where: {
        action: LOAD
        waste: {eer: {contains: "*"}, localUnitId: $localUnitId}
      }
    ) {
      sum {
        residualVolume
      }
    }

    total: wasteOperationAggregate(
      where: {action: LOAD, waste: {localUnitId: $localUnitId}}
    ) {
      sum {
        residualVolume
      }
    }
  }
`;

export default function useVolumeBasedStock({filter}) {
  const response = useQuery(STOCKS_TOTAL_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: filter && {
      ...filter,
      localUnitId:
        typeof filter.localUnitId === 'string'
          ? {equals: filter.localUnitId}
          : filter.localUnitId,
    },
    skip: !filter,
  });

  return useMemo(() => {
    return {
      ...response,
      data: {
        dangerous: round(response.data?.dangerous?.sum?.residualVolume || 0, 2),
        total: round(response.data?.total?.sum?.residualVolume || 0, 2),
      },
    };
  }, [response]);
}

import React, {useMemo} from 'react';
import {AutocompleteInput} from 'react-admin';
import useCountries from 'hooks/useCountries';

function CountryInput(props) {
  const countries = useCountries();

  const choices = useMemo(
    () =>
      countries.map((item) => ({
        id: item.alpha2,
        name: item.name,
      })),
    [countries],
  );

  return (
    <AutocompleteInput
      {...props}
      options={{
        ...props.options,
        suggestionsContainerProps: {
          style: {zIndex: 1400},
        },
        inputProps: {
          autocomplete: 'chrome-off',
          ...props.inputProps,
        },
      }}
      choices={choices}
    />
  );
}

export default React.memo(CountryInput);

import React from 'react';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import List from '@material-ui/core/List';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';
import Tooltip from '@material-ui/core/Tooltip';
import {makeStyles} from '@material-ui/core/styles';
import {useTranslate} from 'react-admin';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
  root: {},
  icon: {minWidth: theme.spacing(5), color: 'inherit'},
  active: {},
  sidebarIsOpen: {
    backgroundColor: '#eee',
    padding: 25,
    paddingTop: 20,
    paddingBottom: 20,
    transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    [theme.breakpoints.down('lg')]: {
      padding: 16,
      paddingTop: 14,
      paddingBottom: 14,
    },
    '@media (max-height:720px) and (min-width:1280px)': {
      paddingTop: 10,
      paddingBottom: 10,
    },
  },
  sidebarIsClosed: {
    paddingLeft: 0,
    transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    [theme.breakpoints.down('md')]: {
      width: 52,
    },
  },
}));

const SubMenu = ({
  handleToggle,
  sidebarIsOpen,
  isOpen,
  name,
  icon,
  children,
  dense,
  ...others
}) => {
  const translate = useTranslate();
  const classes = useStyles(others);
  const isXLg = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const header = (
    <MenuItem
      {...others}
      classes={{root: classes.root, selected: classes.active}}
      selected={isXLg && isOpen}
      dense={dense}
      button
      onClick={handleToggle}>
      {icon && <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>}
      <ListItemText
        primary={name}
        primaryTypographyProps={{variant: 'inherit'}}
      />
      {isOpen ? <ExpandLess /> : <ExpandMore />}
    </MenuItem>
  );

  return (
    <>
      {sidebarIsOpen || isOpen ? (
        header
      ) : (
        <Tooltip title={translate(name, {_: name})} placement="right">
          {header}
        </Tooltip>
      )}
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List
          dense={dense}
          component="div"
          disablePadding
          className={
            sidebarIsOpen ? classes.sidebarIsOpen : classes.sidebarIsClosed
          }>
          {children}
        </List>
        <Divider />
      </Collapse>
    </>
  );
};

export default SubMenu;

import React from 'react';
import ReactDOM from 'react-dom';
import {KeycloakProvider} from '@react-keycloak/web';
import {ApolloProvider} from '@apollo/client';
import * as moment from 'moment';
import 'moment/locale/it';

import {App} from './App.js';
import apollo from './services/apollo';
import keycloak from './services/keycloak';
import * as serviceWorker from './serviceWorker';

moment.locale('it');

ReactDOM.render(
  <KeycloakProvider
    keycloak={keycloak}
    initConfig={{
      onLoad: 'login-required',
      promiseType: 'native',
      checkLoginIframe: false,
      silentCheckSsoRedirectUri:
        window.location.origin + '/silent-check-sso.xhtml',
    }}>
    <ApolloProvider client={apollo}>
      <App />
    </ApolloProvider>
  </KeycloakProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

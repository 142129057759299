import React from 'react';
import {useKeycloak} from '@react-keycloak/web';
import {
  Edit,
  TextInput,
  SimpleForm,
  required,
  useAuthenticated,
} from 'react-admin';
import EmailInput from 'components/atoms/EmailInput';
import PhoneNumberTextInput from 'components/atoms/PhoneNumberTextInput';
import FormToolbar from 'components/atoms/FormToolbar';

export default function ProfileEdit({staticContext, ...props}) {
  // redirects to login if not authenticated
  useAuthenticated();

  const [keycloak, initialized] = useKeycloak();

  if (!initialized) {
    return null;
  }

  return (
    <Edit
      id={keycloak?.subject?.split(':')[2]}
      resource="LoggedUser"
      basePath="/me"
      title="Profile"
      redirect={false}
      undoable={false}
      {...props}>
      <SimpleForm toolbar={<FormToolbar actions={['save']} />}>
        <TextInput fullWidth source="firstName" validate={required()} />
        <TextInput fullWidth source="lastName" validate={required()} />
        <TextInput fullWidth source="jobTitle" />
        <EmailInput fullWidth source="email" validate={required()} unique />
        <PhoneNumberTextInput fullWidth source="phone" />
      </SimpleForm>
    </Edit>
  );
}

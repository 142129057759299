import moment from 'moment';

const numberFormatter = {
  int: new Intl.NumberFormat('it-IT', {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }),
  float: new Intl.NumberFormat('it-IT', {
    style: 'decimal',
  }),
  currency: new Intl.NumberFormat('it-IT', {
    style: 'currency',
    currency: 'EUR',
  }),
  percent: new Intl.NumberFormat('it-IT', {
    style: 'percent',
  }),
  volume: new Intl.NumberFormat('it-IT', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }),
};

export const formattedNumber = (n, style = 'float') =>
  numberFormatter[style].format(n);

export const formattedWasteTitle = (record) => {
  return [record.eer, record.name, record.reference].join(' - ');
};

export const formattedWasteOperationReference = (record) => {
  const prefix = record.action === 'LOAD' ? 'C' : record.action === 'UNLOAD' ? 'S' : 'G';
  const year = moment(record.referenceCreatedAt).format('YY');

  return prefix + record.reference + '/' + year;
};

export const getComponentDisplayName = (Component) =>
  Component.displayName || Component.name || 'Component';

export const formattedWasteFormExpiryDate = (record) =>
  moment(record.referenceCreatedAt).add(90, 'days').format('D/M/YYYY');

export const calcVar = (prev, curr) =>
  !prev || !curr ? 0 : Math.round(((curr - prev) / prev) * 100);

export const today = (position = 'start') => moment()[`${position}Of`]('day');

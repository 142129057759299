import {createMuiTheme} from '@material-ui/core/styles';
import teal from '@material-ui/core/colors/teal';
import lime from '@material-ui/core/colors/lime';
import cyan from '@material-ui/core/colors/cyan';
import yellow from '@material-ui/core/colors/yellow';
import deepOrange from '@material-ui/core/colors/deepOrange';
import lightGreen from '@material-ui/core/colors/lightGreen';

const colors = [
  deepOrange['600'],
  yellow['200'],
  lime['300'],
  lightGreen['500'],
  teal['700'],
  cyan['900'],
];

const theme = createMuiTheme({
  palette: {
    text: {
      primary: '#4a4a4a',
      // secondary: '#2a3b4d',
    },
    primary: {
      main: '#00b1b0',
    },
    secondary: {
      main: '#2a3b4d',
    },
  },
  typography: {
    body1: {
      fontFamily: 'Roboto, sans-serif',
      fontSize: 14,
    },
    h1: {
      fontFamily: 'Open Sans, sans-serif',
      fontSize: 18,
      fontWeight: 600,
      lineHeight: 1.5,
      textTransform: 'uppercase',
    },
    h2: {
      fontFamily: 'Open Sans, sans-serif',
      fontSize: 16,
      fontWeight: 600,
      lineHeight: 1.5,
      textTransform: 'uppercase',
    },
    button: {
      fontFamily: 'Open Sans, sans-serif',
      fontSize: 13,
      fontWeight: 600,
    },
  },
  overrides: {
    RaToolbar: {
      defaultToolbar: {
        flexDirection: 'row-reverse',
      },
    },
    RaEdit: {
      main: {
        alignItems: 'flex-start',
      },
      noActions: {
        marginTop: '56px !important',
      },
      card: {
        flex: 1,
      },
    },
    RaCreate: {
      main: {
        alignItems: 'flex-start',
      },
      noActions: {
        marginTop: '56px !important',
      },
      card: {
        maxWidth: '50%',
        flex: 1,
      },
    },
    RaShow: {
      main: {
        alignItems: 'flex-start',
      },
      noActions: {
        marginTop: '56px !important',
      },
      card: {
        flex: 1,
      },
    },
    RaList: {
      main: {
        alignItems: 'flex-start',
      },
    },
    RaFileInput: {
      dropZone: {
        '&:hover': {
          backgroundColor: '#00b1b0',
          color: '#fff',
        },
      },
    },
    MuiAlertTitle: {
      root: {fontWeight: 600},
    },
    MuiButton: {
      containedPrimary: {
        color: '#fff',
        lineHeight: 2,
      },
    },
    RaDeleteWithConfirmButton: {
      deleteButton: {
        marginLeft: 16,
      },
    },
    MuiFormControl: {
      root: {
        maxWidth: '100%',
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: '#fff!important',
      },
    },
    MuiInputBase: {
      input: {
        backgroundColor: '#fff',
      },
      inputMultiline: {
        backgroundColor: '#fff',
        minHeight: 70,
      },
    },
    MuiInputAdornment: {
      root: {
        transform: 'translateY(8px)',
      },
    },
    MuiCardHeader: {
      title: {
        fontFamily: 'Open Sans, sans-serif',
        fontSize: 16,
        fontWeight: 600,
        lineHeight: 1.5,
        textTransform: 'uppercase',
      },
    },
    MuiCardActions: {
      root: {
        padding: 16,
        paddingTop: 0,
        paddingBottom: 12,
      },
    },
    MuiTableCell: {
      sizeSmall: {
        paddingRight: 16,
      },
    },
    RaLayout: {
      appFrame: {
        marginTop: '75px !important',
      },
    },
    RaTopToolbar: {
      root: {
        backgroundColor: 'transparent! important',
        paddingTop: '8px !important',
      },
    },
    RaListToolbar: {
      toolbar: {
        backgroundColor: 'transparent! important',
      },
    },
  },
});

theme.overrides.RaLayout.content = {
  paddingTop: 14,
  paddingRight: 16,
  paddingLeft: 5,
  paddingBottom: 50,

  [theme.breakpoints.up('lg')]: {
    paddingLeft: 11,
  },
  [theme.breakpoints.up('xl')]: {
    paddingLeft: 19,
    paddingRight: 24,
  },
  [theme.breakpoints.down('xs')]: {
    paddingBottom: 70,
  },
};

theme.overrides.RaEdit.main[theme.breakpoints.down('xs')] = {
  display: 'block',
};

theme.overrides.RaList.main[theme.breakpoints.down('sm')] = {
  flexWrap: 'wrap',
};

theme.overrides.RaLayout.appFrame[theme.breakpoints.down('sm')] = {
  marginTop: '132px !important',
};

theme.overrides.MuiTableCell.sizeSmall[theme.breakpoints.down('sm')] = {
  padding: '6px 8px',
};

const vicotryBaseProps = {
  width: 350,
  height: 350,
  padding: 50,
};

theme.victory = {
  area: {
    ...vicotryBaseProps,
    style: {
      data: {
        fill: theme.palette.grey['900'],
      },
    },
  },
  axis: {
    style: {
      axis: {
        fill: 'transparent',
        stroke: teal['300'],
        strokeWidth: 2,
      },
      axisLabel: {
        padding: theme.spacing(2),
        stroke: 'transparent',
      },
      grid: {
        fill: 'none',
        stroke: teal['500'],
        pointerEvents: 'painted',
      },
      ticks: {
        fill: 'transparent',
        size: 5,
        stroke: teal['300'],
        strokeWidth: 1,
      },
      tickLabels: {
        fill: teal['700'],
      },
    },
  },
  polarDependentAxis: {
    style: {
      ticks: {
        fill: 'transparent',
        size: 1,
        stroke: 'transparent',
      },
    },
  },
  bar: {
    style: {
      data: {
        fill: teal['700'],
        padding: theme.spacing(2),
        strokeWidth: 0,
      },
    },
  },
  boxplot: {
    style: {
      max: {padding: theme.spacing(2), stroke: teal['700'], strokeWidth: 1},
      maxLabels: {padding: theme.spacing(1)},
      median: {padding: theme.spacing(2), stroke: teal['700'], strokeWidth: 1},
      medianLabels: {padding: theme.spacing(1)},
      min: {padding: theme.spacing(2), stroke: teal['700'], strokeWidth: 1},
      minLabels: {padding: theme.spacing(1)},
      q1: {padding: theme.spacing(2), fill: teal['700']},
      q1Labels: {padding: theme.spacing(1)},
      q3: {padding: theme.spacing(2), fill: teal['700']},
      q3Labels: {padding: theme.spacing(1)},
    },
    boxWidth: 20,
  },
  candlestick: {
    style: {
      data: {
        stroke: teal['700'],
      },
      labels: {padding: theme.spacing(1)},
    },
    candleColors: {
      positive: '#ffffff',
      negative: teal['700'],
    },
  },
  chart: {},
  errorbar: {
    borderWidth: 8,
    style: {
      data: {
        fill: 'transparent',
        opacity: 1,
        stroke: teal['700'],
        strokeWidth: 2,
      },
    },
  },
  group: {
    colorScale: [colors],
  },
  histogram: {
    style: {
      data: {
        fill: teal['700'],
        stroke: theme.palette.grey['900'],
        strokeWidth: 2,
      },
    },
  },
  legend: {
    colorScale: colors,
    gutter: 10,
    orientation: 'vertical',
    titleOrientation: 'top',
    style: {
      data: {
        type: 'circle',
      },
      title: {padding: theme.spacing(1)},
    },
  },
  line: {
    style: {
      data: {
        fill: 'transparent',
        opacity: 1,
        stroke: teal['700'],
        strokeWidth: 2,
      },
    },
  },
  pie: {
    colorScale: colors,
    style: {
      data: {
        padding: theme.spacing(2),
        stroke: theme.palette.grey['50'],
        strokeWidth: 1,
      },
      labels: {padding: theme.spacing(4)},
    },
  },
  scatter: {
    style: {
      data: {
        fill: teal['700'],
        opacity: 1,
        stroke: 'transparent',
        strokeWidth: 0,
      },
    },
  },
  stack: {
    colorScale: colors,
  },
  tooltip: {
    style: {padding: 0, pointerEvents: 'none'},
    flyoutStyle: {
      stroke: theme.palette.grey['900'],
      strokeWidth: 1,
      fill: '#f0f0f0',
      pointerEvents: 'none',
    },
    flyoutPadding: 5,
    cornerRadius: 5,
    pointerLength: 10,
  },
  voronoi: {
    style: {
      data: {
        fill: 'transparent',
        stroke: 'transparent',
        strokeWidth: 0,
      },
      labels: {
        padding: 5,
        pointerEvents: 'none',
      },
      flyout: {
        stroke: theme.palette.grey['900'],
        strokeWidth: 1,
        fill: '#f0f0f0',
        pointerEvents: 'none',
      },
    },
  },
};

export default theme;

import {createSlice} from '@reduxjs/toolkit';
import qs from 'query-string';

const initialState = {
  year: new Date().getFullYear(),
  organization: undefined,
  localUnit: undefined,
};

const filters = createSlice({
  name: 'globalFilters',
  initialState,
  reducers: {
    setYear(state, {payload}) {
      return {
        ...state,
        year: payload,
      };
    },
  },
});

const actions = filters.actions;

export default filters;
export {actions};

export const getYear = (state) => state.filters.year;

export const getOrganization = (state) => {
  const search = qs.parse(state.router.location.search);

  if (search.context) {
    const [organizationId] = search.context.split(':');
    return state.admin.resources.Organization?.data[organizationId];
  }
};

export const getLocalUnit = (state) => {
  const search = qs.parse(state.router.location.search);

  if (search.context) {
    const localUnitId = search.context.split(':')[1];

    if (localUnitId) {
      return state.admin.resources.LocalUnit?.data[localUnitId];
    }
  }
};

import React, {useMemo} from 'react';
import sortBy from 'lodash/sortBy';
import {VictoryPie} from 'victory';
import {useTheme} from '@material-ui/core/styles';
import {fade} from '@material-ui/core/styles/colorManipulator';

function DestinationCodePie({data}) {
  const theme = useTheme();
  const chartData = useMemo(() => {
    const value = [];

    if (!data) {
      return value;
    }

    const aggregatedData = data.reduce((carry, item) => {
      const code = item.collectCode || item.disposalCode;

      if (code) {
        if (!carry[code]) {
          carry[code] = 0;
        }

        carry[code] += item.disposedQuantity;
      }

      return carry;
    }, {});

    const totalDisposedQuantity = data.reduce(
      (carry, item) => carry + item.disposedQuantity,
      0,
    );

    return sortBy(
      Object.keys(aggregatedData).map((key) => ({
        x: key,
        y: aggregatedData[key] / totalDisposedQuantity,
      })),
      'x',
    );
  }, [data]);

  const colorscale = useMemo(() => {
    let redColor = theme.palette.error.light;
    let greenColor = theme.palette.success.light;

    return chartData.map((item) => {
      let color = theme.palette.background.default;

      if (item.x[0] === 'D') {
        color = redColor;
        redColor = fade(redColor, 0.8);
      } else {
        color = greenColor;
        greenColor = fade(greenColor, 0.8);
      }

      return color;
    });
  }, [theme, chartData]);

  return (
    <VictoryPie
      labels={({datum}) => `${datum.x}: ${parseFloat(datum.y * 100).toFixed(2)}%`}
      padding={{left: 120, bottom: 0, top: 0, right: 120}}
      colorScale={colorscale}
      data={chartData}
      style={{labels: {fill: 'black', fontSize: 14}}}
    />
  );
}

export default DestinationCodePie;

import React from 'react';
import {Resource} from 'react-admin';
import inflection from 'inflection';
import CircularProgress from '@material-ui/core/CircularProgress';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import ContactsOutlinedIcon from '@material-ui/icons/ContactsOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import loadable from '@loadable/component';
import Layout from 'components/organisms/AdminLayout';
import {Admin} from 'components/organisms/Admin';
import filtersSlice from 'redux/filters';

import customRoutes from './custom.routes';
import './index.css';

const LoadableDashboard = loadable(
  () => import(`components/organisms/Dashboard`),
  {
    fallback: <CircularProgress />,
  },
);

const createResource = (resource, actions) =>
  actions.reduce((carry, action) => {
    const folder = inflection.camelize(resource, true);
    const file = resource + inflection.capitalize(action);

    carry[action] = loadable(() => import(`resources/${folder}/${file}`), {
      fallback: <CircularProgress />,
      resolveComponent: (components) => components[file],
    });

    return carry;
  }, {});

const Authorization = createResource('Authorization', ['create', 'edit']);
const Customer = createResource('Customer', ['list', 'show']);
const CustomerUnit = createResource('CustomerUnit', ['show']);
const EerCode = createResource('EerCode', ['list', 'edit', 'create']);
const LocalUnit = createResource('LocalUnit', ['list', 'edit', 'create']);
const LocalUnitDocument = createResource('LocalUnitDocument', ['create']);
const LocalUnitSite = createResource('LocalUnitSite', [
  'list',
  'edit',
  'create',
]);
const Membership = createResource('Membership', ['create', 'show', 'list']);
const MembershipMember = createResource('MembershipMember', ['create', 'show']);
const Organization = createResource('Organization', ['create', 'edit', 'list']);
const Partnership = createResource('Partnership', ['list', 'show', 'create']);
const Provider = createResource('Provider', ['list', 'show']);
const ProviderAuthorization = createResource('ProviderAuthorization', [
  'list',
  'show',
  'create',
]);
const ProviderUnit = createResource('ProviderUnit', ['show', 'create']);
const ProviderVehicle = createResource('ProviderVehicle', [
  'list',
  'show',
  'create',
]);
const User = createResource('User', ['create', 'edit', 'list']);
const Vehicle = createResource('Vehicle', ['list', 'edit', 'create']);
const Waste = createResource('Waste', ['list', 'show', 'create', 'edit']);
const WasteAssignment = createResource('WasteAssignment', ['create']);
const WasteForm = createResource('WasteForm', ['create', 'list', 'show']);
const WasteFormActor = createResource('WasteFormActor', ['create']);
const WasteOperation = createResource('WasteOperation', [
  'list',
  'create',
  'edit',
]);
const WasteOperationRegister = createResource('WasteOperationRegister', [
  'list',
  'create',
  'show',
]);

const WasteOperationRegisterPage = createResource(
  'WasteOperationRegisterPage',
  ['show', 'list'],
);


const EnvironmentalDeclaration = createResource('EnvironmentalDeclaration', [
  'edit',
  'list',
  'create',
]);

const AlertIcon = createResource('AlertIcon', ['list', 'show', 'create', 'edit']);
const Alert = createResource('Alert', ['create', 'edit']);

const initialState = {
  admin: {
    ui: {
      sidebarOpen: true,
    },
  },
};

const WasteOperationTask = createResource('WasteOperationTask', ['create']);

let isDesktop = false;

if (typeof window !== 'undefined') {
  isDesktop = window.matchMedia('(min-width: 1280px)').matches;
}

if (!isDesktop) {
  initialState.admin.ui.sidebarOpen = false;
}

export function App() {
  return (
    <Admin
      dashboard={LoadableDashboard}
      layout={Layout}
      initialState={initialState}
      customReducers={{filters: filtersSlice.reducer}}
      customRoutes={customRoutes}>
      <Resource name="Authorization" {...Authorization} />
      <Resource name="Customer" {...Customer} icon={ContactsOutlinedIcon} />
      <Resource name="CustomerUnit" {...CustomerUnit} />
      <Resource name="EerCode" {...EerCode} />
      <Resource name="File" />
      <Resource name="LocalUnit" {...LocalUnit} />
      <Resource name="LocalUnitSite" {...LocalUnitSite} />
      <Resource name="LocalUnitDocument" {...LocalUnitDocument} />
      <Resource name="LocalUnitSequence" />
      <Resource name="Membership" {...Membership} />
      <Resource name="MembershipMember" {...MembershipMember} />
      <Resource name="Organization" {...Organization} />
      <Resource name="Partner" />
      <Resource name="PartnerMember" />
      <Resource name="Partnership" {...Partnership} />
      <Resource name="PartnerUnit" />
      <Resource name="Provider" {...Provider} />
      <Resource name="ProviderAuthorization" {...ProviderAuthorization} />
      <Resource name="ProviderUnit" {...ProviderUnit} />
      <Resource name="ProviderVehicle" {...ProviderVehicle} />
      <Resource name="User" {...User} />
      <Resource name="Vehicle" {...Vehicle} />
      <Resource name="AlertIcon" {...AlertIcon} />
      <Resource name="Alert" {...Alert} />
      <Resource name="Waste" {...Waste} icon={DeleteOutlinedIcon} />
      <Resource name="WasteAnalysis" />
      <Resource name="WasteAssignment" {...WasteAssignment} />
      <Resource name="WasteForm" {...WasteForm} icon={ListAltOutlinedIcon} />
      <Resource name="WasteFormActor" {...WasteFormActor} />
      <Resource
        name="WasteOperation"
        options={{label: 'Registro C/S'}}
        {...WasteOperation}
        icon={ImportExportIcon}
      />
      <Resource name="WasteOperationConnection" />
      <Resource
        name="WasteOperationRegisterPage"
        {...WasteOperationRegisterPage}
      />
      <Resource
        name="WasteOperationRegister"
        options={{label: 'Registro Vidima'}}
        {...WasteOperationRegister}
        icon={LibraryBooksIcon}
      />
      <Resource name="WasteOperationTask" {...WasteOperationTask} />
      <Resource name="EnvironmentalDeclaration" {...EnvironmentalDeclaration} />
      <Resource name="UserInvite" />
      <Resource name="ReferenceWasteAssignment" />
      <Resource name="ReferenceProviderUnit" />
      <Resource name="ReferenceCustomerUnit" />
      <Resource name="ConsultantCustomerUnit" />
      <Resource name="LoggedUser" />
      <Resource name="CustomerUnitSite" />
      <Resource name="DocumentCategory" />
      <Resource name="IstatCity" />
    </Admin>
  );
}

import {AbilityBuilder, Ability} from '@casl/ability';

export const ACTIONS = {
  manage: 'manage', // alias for all actions
  access: 'access',
  create: 'create',
  read: 'read',
  update: 'update',
  delete: 'delete',
  archive: 'archive',
};

export const SUBJECTS = {
  AdminDashboard: 'AdminDashboard', // to identify admins
  Organization: 'Organization',
  LocalUnit: 'LocalUnit',
  Membership: 'Membership',
  WasteOperation: 'WasteOperation',
  WasteForm: 'WasteForm',
  User: 'User',
};

export default function defineAbilityFor(loggedUser, roles = []) {
  const {can, cannot, build} = new AbilityBuilder(Ability);

  if (loggedUser) {
    if (roles.includes('admin')) {
      can(ACTIONS.manage, 'all'); // read-write access to everything
    } else {
      can(ACTIONS.create, SUBJECTS.Organization); // anybody can create an organization

      can(
        [ACTIONS.create, ACTIONS.read, ACTIONS.update],
        SUBJECTS.WasteOperation,
      );

      can(ACTIONS.delete, SUBJECTS.WasteOperation, {
        status: {$nin: ['PROTOCOLLED', 'PRINTED']},
      });

      const {memberships} = loggedUser;
      for (let index = 0; index < memberships.length; index++) {
        const membership = memberships[index];

        if (membership.organization) {
          can([ACTIONS.read, ACTIONS.update], SUBJECTS.Organization, {
            id: membership.organization.id,
          });

          can(ACTIONS.manage, SUBJECTS.LocalUnit, {
            'organization.id': membership.organization.id,
          });

          switch (membership.organization.type) {
            case 'WASTE_PRODUCING':
              can([ACTIONS.update, ACTIONS.delete], SUBJECTS.WasteForm, {
                'waste.localUnit.organization.id': membership.organization.id,
              });
              break;
            case 'WASTE_MANAGING':
              can(ACTIONS.create, SUBJECTS.WasteForm, {
                'waste.assignments': {
                  $elemMatch: {
                    billable: true,
                    'provider.organization.id': membership.organization.id,
                  },
                },
              });
              break;
            default:
              break;
          }

          if (membership.role === 'ADMIN') {
            can(ACTIONS.archive, SUBJECTS.Organization, {
              id: membership.organization.id,
            });
            console.log('can manage membership', membership.organization.id);
            can(ACTIONS.manage, SUBJECTS.Membership, {
              'organization.id': membership.organization.id,
            });
          } else {
            can(ACTIONS.read, SUBJECTS.Membership, {
              'organization.id': membership.organization.id,
            });

            can(
              [ACTIONS.create, ACTIONS.update, ACTIONS.delete],
              SUBJECTS.Membership,
              {
                'organization.id': membership.organization.id,
                role: {$not: 'ADMIN'},
              },
            );

            can(ACTIONS.create, SUBJECTS.Membership, {
              'organization.id': membership.organization.id,
              'organization.memberships': {$size: 0},
              role: 'ADMIN',
            });

            can(ACTIONS.create, SUBJECTS.Membership, {
              'organization.id': membership.organization.id,
              'organization.memberships.role': {$not: 'ADMIN'},
              role: 'ADMIN',
            });
          }
        } else if (membership.localUnit) {
          can(ACTIONS.read, SUBJECTS.Organization, {
            id: membership.localUnit.organizationId,
          });

          can([ACTIONS.read, ACTIONS.update], SUBJECTS.LocalUnit, {
            id: membership.localUnit.id,
          });

          switch (membership.localUnit.organization.type) {
            case 'WASTE_PRODUCING':
              can([ACTIONS.update, ACTIONS.delete], SUBJECTS.WasteForm, {
                'waste.localUnit.id': membership.localUnit.id,
              });
              break;
            case 'WASTE_MANAGING':
              can(ACTIONS.create, SUBJECTS.WasteForm, {
                'waste.assignments': {
                  $elemMatch: {
                    billable: true,
                    'provider.id': membership.localUnit.id,
                  },
                },
              });

              can(
                ACTIONS.update,
                SUBJECTS.WasteForm,
                {
                  'waste.assignments': {
                    $elemMatch: {
                      billable: true,
                      'provider.id': membership.localUnit.id,
                    },
                  },
                },
                ['formFile'],
              );
              break;
            default:
              break;
          }

          can(ACTIONS.read, SUBJECTS.Membership, {
            'organization.id': membership.localUnit.organizationId,
          });

          if (membership.role === 'ADMIN') {
            can(ACTIONS.archive, SUBJECTS.LocalUnit, {
              id: membership.localUnit.id,
            });

            can(ACTIONS.manage, SUBJECTS.Membership, {
              'localUnit.id': membership.localUnit.id,
            });
          } else {
            can(ACTIONS.read, SUBJECTS.Membership, {
              'localUnit.id': membership.localUnit.id,
            });

            can(
              [ACTIONS.create, ACTIONS.update, ACTIONS.delete],
              SUBJECTS.Membership,
              {
                'localUnit.id': membership.localUnit.id,
                role: {$ne: 'ADMIN'},
              },
            );
          }
        }
      }

      // inverse rules
      cannot(ACTIONS.create, SUBJECTS.Organization, ['public']);
      cannot(ACTIONS.update, SUBJECTS.Organization, ['public', 'taxId']);
      cannot(ACTIONS.delete, SUBJECTS.Membership, {'user.id': loggedUser.id}); // a user cannot delete himself from org./u.l.
      cannot(ACTIONS.update, SUBJECTS.WasteOperation, [
        'status',
        'action',
        'protocolReference',
        'protocolReferenceCreatedAt',
        'collectValue',
        'disposalCost',
        'transportCost',
        'residualQuantity',
        'residualVolume',
        'waste',
      ]);

      cannot(
        ACTIONS.update,
        SUBJECTS.WasteOperation,
        [
          'reference',
          'referenceCreatedAt',
          'formReference',
          'formCreatedAt',
          'wasteMetadata',
          'siteMetadata',
          'quantity',
          'weight',
          'site',
          'tasks',
        ],
        {
          status: {$in: ['PROTOCOLLED', 'PRINTED']},
        },
      );

      cannot(ACTIONS.update, SUBJECTS.WasteOperation, ['loadOperations'], {
        status: 'PRINTED',
      });
    }

    // Following rules are applied to all users
    cannot(ACTIONS.delete, SUBJECTS.User, {id: loggedUser.id}); // nobody can delete himself
    cannot(ACTIONS.delete, SUBJECTS.Organization);
    cannot(ACTIONS.delete, SUBJECTS.LocalUnit);
    cannot(ACTIONS.delete, SUBJECTS.WasteForm, {status: {$ne: 'DRAFT'}});
    cannot(ACTIONS.update, SUBJECTS.WasteForm, 'status', {
      status: {$ne: 'DRAFT'},
    });
  }

  return build();
}

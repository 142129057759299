import {useState, useCallback} from 'react';
import {useUpdate, useRefresh, useNotify, useRedirect} from 'react-admin';

const useArchiveWithConfirmController = ({
  resource,
  record,
  redirectParams,
  onClick,
  onSuccess,
  onFailure,
}) => {
  const [open, setOpen] = useState(false);
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  const [archiveOne, {loading}] = useUpdate(
    resource,
    record?.id,
    {...record, archived: true},
    record,
    {
      onSuccess: (response) => {
        setOpen(false);
        if (onSuccess === undefined) {
          notify('ra.notification.archived', 'info', {smart_count: 1});
          if (redirectParams !== false) {
            redirect(...redirectParams);
          }
          refresh();
        } else {
          onSuccess(response);
        }
      },
      onFailure: (error) => {
        setOpen(false);
        if (onFailure === undefined) {
          notify(
            typeof error === 'string'
              ? error
              : error.message || 'ra.notification.http_error',
            'warning',
          );
        } else {
          onFailure(error);
        }
      },
      undoable: false,
    },
  );

  const handleDialogOpen = useCallback((e) => {
    setOpen(true);
    e.stopPropagation();
  }, []);

  const handleDialogClose = useCallback((e) => {
    setOpen(false);
    e.stopPropagation();
  }, []);

  const handleArchive = useCallback(
    (event) => {
      archiveOne();
      if (typeof onClick === 'function') {
        onClick(event);
      }
    },
    [archiveOne, onClick],
  );

  return {open, loading, handleDialogOpen, handleDialogClose, handleArchive};
};

export default useArchiveWithConfirmController;

import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Link as RouterLink} from 'react-router-dom';
import MuiLink from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
  root: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

export default function Link(props) {
  const classes = useStyles();

  return (
    <MuiLink component={RouterLink} classes={{root: classes.root}} {...props} />
  );
}

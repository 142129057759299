import React, {useMemo, useEffect, useRef, useState, useCallback} from 'react';
import {useSelector} from 'react-redux';
import {stringify} from 'query-string';
import {makeStyles} from '@material-ui/core/styles';
import {fade} from '@material-ui/core/styles/colorManipulator';
import {
  TopToolbar,
  List,
  Datagrid,
  TextField,
  ExportButton,
  Button,
  CreateButton,
  sanitizeListRestProps,
  useListContext,
  useRefresh,
  useGetIdentity,
  useDataProvider,
} from 'react-admin';
import usePermissions from 'hooks/usePermissions';
import Empty from 'components/atoms/Empty';
import DatagridActions from 'components/atoms/DatagridActions';
import useGlobalFilterContext from 'hooks/useGlobalFilterContext';
import {linkToRecord} from 'ra-core';

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: fade(theme.palette.error.main, 0.12),
      // Reset on mouse devices
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
}));

function ConsultantCustomerDeleteButton(props) {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const {identity} = useGetIdentity();
  const refresh = useRefresh();
  const permissions = usePermissions();
  const dataProvider = useDataProvider();

  const handleDelete = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      setLoading(true);

      const organizationFilter = {id: {equals: props.record.id}};
      dataProvider
        .getList('Membership', {
          pagination: {page: 1, perPage: 100},
          sort: {},
          filter: {
            user: {id: {equals: identity.id}},
            OR: [
              {organization: organizationFilter},
              {localUnit: {organization: organizationFilter}},
            ],
          },
        })
        .then(({data}) => {
          if (data) {
            return Promise.all(
              data.map((item) =>
                dataProvider.delete('Membership', {
                  id: item.id,
                }),
              ),
            );
          }
        })
        .then(() => {
          refresh();
          permissions.refetch();
        });
    },
    [identity, dataProvider, permissions, props.record, refresh],
  );

  return (
    <Button
      className={classes.deleteButton}
      label="ra.action.delete"
      onClick={handleDelete}
      disabled={loading}
    />
  );
}

function ConsultantCustomerCreateButton(props) {
  const {identity} = useGetIdentity();

  return (
    <CreateButton
      {...props}
      to={{
        pathname: `${props.basePath}/create`,
        search: stringify({
          source: JSON.stringify({
            type: 'WASTE_PRODUCING',
            memberships: {
              role: 'CONSULTANT',
              user: identity?.id,
            },
          }),
        }),
      }}
    />
  );
}

function ConsultantCustomerActions(props) {
  const {className, exporter, filters, maxResults, basePath, ...rest} = props;
  const {currentSort, resource, filterValues, total} = useListContext();

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <ConsultantCustomerCreateButton basePath={basePath} />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
    </TopToolbar>
  );
}

function ConsultantCustomerListEmpty(props) {
  return (
    <Empty
      {...props}
      create={
        <ConsultantCustomerCreateButton
          variant="contained"
          basePath={props.basePath}
        />
      }
    />
  );
}

export default function CustomerList(props) {
  const {identity} = useGetIdentity();
  const mounted = useRef(false);
  const [globalFilters, setGlobalFilters] = useGlobalFilterContext();

  const consultancyOrganization = useSelector((state) => {
    if (!state.admin.resources.Organization) {
      return null;
    }

    return Object.values(state.admin.resources.Organization.data).find(
      (org) => org.type === 'WASTE_CONSULTANCY',
    );
  });

  const filter = useMemo(() => {
    const membership = {
      role: 'CONSULTANT',
    };

    if (identity) {
      membership.userId = {equals: identity.id};
    }

    return {
      OR: [
        {memberships: {some: membership}},
        {localUnits: {some: {memberships: {some: membership}}}},
      ],
    };
  }, [identity]);

  // if somehow the user end up to this page
  // and the selected organization is not a waste consultancy
  // organization, we force the state to update to update
  useEffect(() => {
    if (
      !mounted.current &&
      consultancyOrganization &&
      globalFilters.organization !== consultancyOrganization.id
    ) {
      setGlobalFilters({
        organization: consultancyOrganization.id,
        localUnit: null,
      });
    }
  }, [globalFilters, setGlobalFilters, consultancyOrganization]);

  useEffect(() => {
    mounted.current = true;
  }, []);

  const onRowClick = (id, basePath) => {
    return linkToRecord(basePath, id) + '?showBack=true';
  };

  return (
    <List
      {...props}
      key={props.version}
      resource="Organization"
      basePath="/Organization"
      hasCreate={true}
      bulkActionButtons={false}
      actions={<ConsultantCustomerActions />}
      empty={<ConsultantCustomerListEmpty />}
      filter={filter}>
      <Datagrid rowClick={onRowClick}>
        <TextField source="name" />
        <TextField source="fiscalCode" />
        <TextField source="taxId" />
        <DatagridActions>
          <ConsultantCustomerDeleteButton />
        </DatagridActions>
      </Datagrid>
    </List>
  );
}

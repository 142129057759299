import React, {useCallback, useState} from 'react';
import {Redirect} from 'react-router-dom';
import {
  SimpleForm,
  TextInput,
  required,
  minLength,
  Create,
  useRedirect,
  useNotify,
  SelectInput,
  Toolbar,
  SaveButton,
} from 'react-admin';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Container from '@material-ui/core/Container';
import {Alert, AlertTitle} from '@material-ui/lab';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useMutation, gql} from '@apollo/client';
import {ACTIONS, SUBJECTS} from 'services/permissions';
import usePermissions from 'hooks/usePermissions';
import useHomePath from 'hooks/useHomePath';
import BaseLayout from 'components/organisms/BaseLayout';
import AppLoader from 'components/atoms/AppLoader';
import Logo from 'components/atoms/Logo';
import TaxIdInput from 'components/atoms/TaxIdInput';
import AddressInput from 'components/atoms/AddressInput';
import PhoneNumberTextInput from 'components/atoms/PhoneNumberTextInput';
import EnhancedRadioButtonGroupInput from 'components/atoms/EnhancedRadioButtonGroupInput';

const REDEEM_MUTATION = gql`
  mutation RedeemMutation($taxId: String!) {
    redeemOneOrganization(where: {taxId: $taxId}) {
      id
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    minHeight: '100vh',
    alignItems: 'center',
  },
  main: {
    marginTop: '0 !important',
    paddingTop: 0,
    paddingLeft: 0,
  },
  card: {
    maxWidth: 'initial',
  },
  row: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    '&> div:first-child': {
      marginRight: 10,
    },
  },
  toolbar: {
    justifyContent: 'flex-end',
  },
}));

const CustomToolbar = (props) => {
  return (
    <Toolbar {...props} className={useStyles().toolbar}>
      {props.showSave && <SaveButton />}
    </Toolbar>
  );
};

function OnboardingOrganizationView(props) {
  const classes = useStyles();
  const redirect = useRedirect();
  const redirectPath = useHomePath();
  const [validationResult, setValidationResult] = useState();
  const [redeemDialogOpen, setRedeemDialogOpen] = useState(false);
  const [redeemMut] = useMutation(REDEEM_MUTATION);

  const closeRedeemDialog = () => setRedeemDialogOpen(false);

  const doRedeem = async () => {
    try {
      await redeemMut({
        variables: {taxId: validationResult?.partners?.[0]?.taxId},
      });
      closeRedeemDialog();
      redirect(redirectPath);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Create
      {...props}
      classes={{main: classes.main, card: classes.card}}
      resource="Organization"
      basePath="/Organization">
      <SimpleForm
        toolbar={
          <CustomToolbar showSave={!validationResult?.partners?.length} />
        }>
        <TextInput
          source="name"
          validate={[required(), minLength(2)]}
          fullWidth
        />
        <PhoneNumberTextInput source="phone" fullWidth />
        <TaxIdInput
          source="taxId"
          validate={required()}
          fullWidth
          setValidationResult={setValidationResult}
        />
        <AddressInput
          showAutocomplete
          fullWidth
          source="address"
          validate={required()}
        />
        {validationResult?.partners?.length ? (
          <>
            {validationResult?.partners?.[0]?.public === false ? (
              <Alert
                severity="warning"
                action={
                  <Button
                    target="_blank"
                    href="mailto:info@rifiutoo.com"
                    color="primary"
                    variant="contained">
                    Contattaci
                  </Button>
                }>
                <AlertTitle>Attenzione</AlertTitle>
                Questa azienda (partita iva) è già presente nel database di
                Rifiutoo. Contatta il responsabile ambiente della tua società o
                Rifiutoo.
              </Alert>
            ) : (
              <>
                <Alert
                  severity="warning"
                  action={
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => setRedeemDialogOpen(true)}>
                      Riscatta
                    </Button>
                  }>
                  <AlertTitle>Attenzione</AlertTitle>
                  Questa azienda (partita iva) è già presente nel database di
                  Rifiutoo ma non è associata ad alcun utente. Se ne sei il
                  legittimo proprietario puoi prenderne possesso.
                </Alert>
                <Dialog open={redeemDialogOpen} onClose={closeRedeemDialog}>
                  <DialogTitle>Attenzione</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Stai riscattando il profilo dell'azienda{' '}
                      {validationResult?.partners?.[0]?.name} con partita iva{' '}
                      {validationResult?.partners?.[0]?.taxId}. Continuando
                      questa procedura dichiari di avere legittimo mandato a
                      gestire il profilo aziendale e dichiari inoltre di
                      assumerti la responsabilità del contenuto del profilo
                      aziendale.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={doRedeem} autoFocus color="primary">
                      Riscatta
                    </Button>
                    <Button
                      onClick={closeRedeemDialog}
                      autoFocus
                      color="primary">
                      Annulla
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            )}
          </>
        ) : (
          <EnhancedRadioButtonGroupInput
            fullWidth
            row={false}
            source="type"
            validate={required()}
            choices={[
              {
                id: 'WASTE_PRODUCING',
                name: 'enums.OrganizationType.WASTE_PRODUCING',
              },
              {
                id: 'WASTE_CONSULTANCY',
                name: 'enums.OrganizationType.WASTE_CONSULTANCY',
              },
              {
                id: 'WASTE_MANAGING',
                name: 'enums.OrganizationType.WASTE_MANAGING',
                // disabled: true,
              },
            ]}
          />
        )}
      </SimpleForm>
    </Create>
  );
}

const OnboardingLocalUnitView = ({initialValues, ...others}) => {
  const classes = useStyles();

  return (
    <Create
      {...others}
      classes={{main: classes.main, card: classes.card}}
      resource="LocalUnit"
      basePath="/LocalUnit">
      <SimpleForm initialValues={initialValues}>
        <TextInput
          source="name"
          validate={[required(), minLength(2)]}
          fullWidth
        />
        <AddressInput
          showAutocomplete
          fullWidth
          source="address"
          validate={required()}
        />
        <SelectInput
          fullWidth
          source="settings.stockType"
          validate={required()}
          choices={[
            {
              id: 'VOLUME_BASED',
              name: 'enums.StockType.VOLUME_BASED',
            },
            {
              id: 'TIME_BASED',
              name: 'enums.StockType.TIME_BASED',
            },
          ]}
        />
      </SimpleForm>
    </Create>
  );
};

function OnboardingView(props) {
  const classes = useStyles();
  const notify = useNotify();
  const redirect = useRedirect();
  const redirectPath = useHomePath();
  const [organization, setOrganization] = useState(null);
  const {loaded, permissions} = usePermissions();

  const handleOrganizationCreated = useCallback(
    ({data}) => {
      notify('ra.notification.created', 'info', {smart_count: 1});

      // permissions.refetch();

      if (data.type === 'WASTE_CONSULTANCY') {
        redirect('edit', '/Organization', data.id, data);
      } else {
        setOrganization(data);
      }
    },
    [notify, redirect],
  );

  const handleLocalUnitCreated = useCallback(() => {
    notify('ra.notification.created', 'info', {smart_count: 1});

    if (organization) {
      redirect('edit', '/Organization', organization.id, organization);
    }
  }, [notify, organization, redirect]);

  if (!loaded) {
    return <AppLoader />;
  }

  // If the user can read at least 1 organization there's no need to show the onboarding page
  // so we redirect the user to the home page
  if (permissions.can(ACTIONS.read, SUBJECTS.Organization)) {
    return <Redirect to={redirectPath} />;
  }

  return (
    <div className={classes.root}>
      <Container maxWidth="sm">
        <Card>
          <CardHeader
            title={<Logo />}
            align="center"
            subheader={
              !organization
                ? 'Inserisci i dati della tua ragione sociale'
                : 'Crea la tua prima unità locale'
            }
          />
          {!organization ? (
            <OnboardingOrganizationView
              {...props}
              onSuccess={handleOrganizationCreated}
            />
          ) : (
            <OnboardingLocalUnitView
              {...props}
              initialValues={{
                organization: organization.id,
                address: organization.address,
                settings: {},
              }}
              onSuccess={handleLocalUnitCreated}
            />
          )}
        </Card>
      </Container>
    </div>
  );
}

export default function Onboarding() {
  return (
    <BaseLayout>
      <OnboardingView />
    </BaseLayout>
  );
}

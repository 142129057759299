import React, {useMemo, useState, useEffect, useCallback} from 'react';
import moment from 'moment';
import {useSelector} from 'react-redux';
import {useFormState} from 'react-final-form';
import {gql, useQuery} from '@apollo/client';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import {FormWithRedirect, ReferenceInput, SelectInput} from 'react-admin';
import {getLocalUnit} from 'redux/filters';
import {formattedWasteTitle, today} from 'helpers';
import ProductionBarChart from 'components/organisms/Analytics/ProductionBarChart';
import EconomicsBarChart from 'components/organisms/Analytics/EconomicsBarChart';
import DateRangeInput from 'components/atoms/DateRangeInput';
import AnalyticsBox from 'components/atoms/AnalyticsBox';
import Variation from 'components/organisms/Analytics/Variation';
import DestinationPie from 'components/organisms/Analytics/DestinationPie';
import DestinationCodePie from 'components/organisms/Analytics/DestinationCodePie';

const useStyles = makeStyles((theme) => ({
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(6, 1fr)',
    gap: '20px',
    padding: '20px',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      padding: '16px',

      '&>*': {
        marginBottom: 16,
      },
    },
  },
  field: {
    display: 'block',
  },
  production: {
    gridArea: '2 / 1 / 3 / 4',
    width: 700,
    padding: 20,
  },
  economics: {gridArea: '2 / 4 / 3 / 7', width: 700, padding: 20},
  wasteDestination: {
    padding: 20,
  },
  wasteDestinationContent: {
    display: 'flex',
  },
  root: {margin: '10px'},
  paper: {
    padding: 12,
    paddingTop: 8,
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardContent: {
    flex: 1,
  },
  variation: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const ANALYTICS_QUERY = gql`
  query analyticsAggregation(
    $waste: WasteWhereInput!
    $currentYear: DateTimeNullableFilter!
    $previousYear: DateTimeNullableFilter!
    $wasteProductionYear: Int!
  ) {
    currYearWasteAggr: wasteOperationAggregate(
      where: {action: UNLOAD, waste: $waste, referenceCreatedAt: $currentYear}
    ) {
      sum {
        disposedQuantity
        disposalCost
        transportCost
        collectValue
      }
    }

    prevYearWasteAggr: wasteOperationAggregate(
      where: {action: UNLOAD, waste: $waste, referenceCreatedAt: $previousYear}
    ) {
      sum {
        disposedQuantity
        disposalCost
        transportCost
        collectValue
      }
    }

    wasteProductionReports(
      where: {year: {equals: $wasteProductionYear}, waste: $waste}
    ) {
      disposedQuantity
      month
      year
      costs
      collectValue
    }

    wasteDestinationReports(
      where: {year: {equals: $wasteProductionYear}, waste: $waste}
    ) {
      year
      month
      disposedQuantity
      collectCode
      disposalCode
    }
  }
`;

const maxFromDate = today().toDate();

const AnalyticsFiltersFormView = ({handleSubmitWithRedirect, className}) => {
  const classes = useStyles();
  const {values} = useFormState();

  return (
    <div className={className}>
      <Typography variant="overline" component="h4">
        Filtri
      </Typography>
      <DateRangeInput
        fullWidth
        max={maxFromDate}
        sourceStart="fromDate"
        sourceEnd="toDate"
        onChange={handleSubmitWithRedirect}
      />
      {values.localUnit && (
        <ReferenceInput
          fullWidth
          resettable
          source="waste"
          reference="Waste"
          label="Rifiuto"
          filter={{localUnitId: {equals: values.localUnit}}}
          className={classes.input}
          onChange={handleSubmitWithRedirect}>
          <SelectInput optionText={formattedWasteTitle} />
        </ReferenceInput>
      )}
    </div>
  );
};

function Analytics() {
  const classes = useStyles();
  const localUnit = useSelector(getLocalUnit);

  const [filter, setFilter] = useState({
    fromDate: moment().startOf('year').toISOString(),
    toDate: today('end').toISOString(),
    localUnit: localUnit?.id || null,
    waste: null,
  });

  const referenceWaste = useSelector(
    (state) => state.admin.resources.Waste?.data[filter.waste],
  );

  const queryVariables = useMemo(() => {
    return {
      waste: filter.waste
        ? {id: {equals: filter.waste}}
        : filter.localUnit
        ? {localUnitId: {equals: filter.localUnit}}
        : undefined,
      currentYear: {gte: filter.fromDate, lte: filter.toDate},
      previousYear: {
        gte: moment(filter.fromDate).subtract(1, 'year').toISOString(),
        lte: moment(filter.toDate).subtract(1, 'year').toISOString(),
      },
      wasteProductionYear: moment(filter.fromDate).year(),
    };
  }, [filter]);

  const {data, called} = useQuery(ANALYTICS_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: queryVariables,
    skip: !filter.localUnit,
  });

  const segments = useMemo(() => {
    const referenceDate = moment(filter.fromDate);
    return {
      end: referenceDate.format('YYYY'),
      start: referenceDate.subtract(1, 'year').format('YYYY'),
    };
  }, [filter.fromDate]);

  const segmentsData = {
    end: data?.currYearWasteAggr?.sum || {},
    start: data?.prevYearWasteAggr?.sum || {},
  };

  const handleFilter = useCallback((values) => {
    setFilter((state) => ({...state, ...values}));
  }, []);

  // reset the filter when the localUnit changes
  useEffect(() => {
    setFilter((state) => ({
      ...state,
      localUnit: localUnit?.id || null,
      waste: localUnit?.id !== state.localUnit ? null : state.waste,
    }));
  }, [localUnit]);

  const defaultPreviousProduction = useMemo(() => {
    return referenceWaste
      ? Math.round(
          (referenceWaste.annualProduction.quantity / 365) *
            moment().get('dayOfYear'),
        )
      : 0;
  }, [referenceWaste]);

  if (!called) {
    return <CircularProgress />;
  }

  return (
    <>
      <div className={classes.grid}>
        <Card>
          <CardContent>
            <FormWithRedirect
              initialValues={filter}
              save={handleFilter}
              render={(formProps) => (
                <AnalyticsFiltersFormView
                  className={classes.filters}
                  {...formProps}
                />
              )}
            />
          </CardContent>
        </Card>

        <AnalyticsBox
          unit="kg"
          title="Produzione"
          currYear={segments.end}
          currValue={segmentsData.end.disposedQuantity}
          prevYear={segments.start}
          prevValue={
            segmentsData.start.disposedQuantity || defaultPreviousProduction
          }
        />

        <Card className={classes.card}>
          <CardHeader title="Target riduzione" />
          <CardContent className={classes.cardContent}>
            {filter.waste ? (
              <Typography align="right" variant="subtitle1">
                {segments.end}
                <Box component="b" ml={3}>
                  - {(referenceWaste?.reductionTarget || 0) * 100} %
                </Box>
              </Typography>
            ) : (
              <Typography align="right" variant="subtitle1">
                Seleziona un rifiuto
              </Typography>
            )}
          </CardContent>
          <CardActions>
            {referenceWaste &&
              (segmentsData.start.disposedQuantity ||
                defaultPreviousProduction) &&
              segmentsData.end.disposedQuantity > 0 && (
                <Variation
                  hideArrow
                  prevSum={
                    segmentsData.start.disposedQuantity ||
                    defaultPreviousProduction
                  }
                  currSum={segmentsData.end.disposedQuantity}
                  color={(v) => {
                    const target = (referenceWaste.reductionTarget || 0) * 100;
                    return v <= target * -1 ? 'green' : 'red';
                  }}
                />
              )}
          </CardActions>
        </Card>

        <AnalyticsBox
          unit="€"
          title="Costi totali"
          currYear={segments.end}
          currValue={
            segmentsData.end.disposalCost + segmentsData.end.transportCost || 0
          }
          prevYear={segments.start}
          prevValue={
            segmentsData.start.disposalCost +
              segmentsData.start.transportCost || 0
          }
        />

        <AnalyticsBox
          unit="€"
          title="Ricavi totali"
          currYear={segments.end}
          currValue={segmentsData.end.collectValue}
          prevYear={segments.start}
          prevValue={segmentsData.start.collectValue}
        />

        <Card>
          <CardHeader title="Valori economici unitari" />
          <CardContent>
            {!filter.waste ? (
              <Typography align="right" variant="subtitle1">
                Seleziona un rifiuto
              </Typography>
            ) : (
              <>
                <Typography paragraph>
                  costo smaltimento{' - '}
                  {
                    +(
                      segmentsData.end.disposalCost /
                        segmentsData.end.disposedQuantity || 0
                    ).toFixed(2)
                  }{' '}
                  €/kg
                </Typography>
                <Typography paragraph>
                  valore di recupero{' - '}
                  {
                    +(
                      segmentsData.end.collectValue /
                        segmentsData.end.disposedQuantity || 0
                    ).toFixed(2)
                  }{' '}
                  €/kg
                </Typography>
                <Typography paragraph>
                  costi trasporto -{' '}
                  {
                    +(
                      segmentsData.end.transportCost /
                        (segmentsData.end.disposedQuantity / 1000) || 0
                    ).toFixed(2)
                  }{' '}
                  €/ton
                </Typography>
              </>
            )}
          </CardContent>
        </Card>

        <ProductionBarChart
          wasteProductionReports={data?.wasteProductionReports}
        />
        <EconomicsBarChart
          wasteProductionReports={data?.wasteProductionReports}
        />
      </div>
      <div className={classes.wasteDestination}>
        <Card>
          <CardHeader
            title="Destinazione dei rifiuti"
            subheader={
              !data?.wasteDestinationReports.length &&
              'Aggiungi dei codici di smaltimento per visionare il diagramma a torta.'
            }
          />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <DestinationPie data={data?.wasteDestinationReports} />
              </Grid>
              <Grid item xs={12} md={6}>
                <DestinationCodePie data={data?.wasteDestinationReports} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    </>
  );
}

export default Analytics;

import * as React from 'react';
import {
  FormControl,
  FormHelperText,
  FormLabel,
  RadioGroup,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import get from 'lodash/get';
import {useInput, FieldTitle, warning, InputHelperText} from 'react-admin';

import EnhancedRadioButtonGroupInputItem from './EnhancedRadioButtonGroupInputItem';

const useStyles = makeStyles((theme) => ({
  label: {
    transform: 'translate(0, 5px) scale(0.75)',
    transformOrigin: `top ${theme.direction === 'ltr' ? 'left' : 'right'}`,
  },
}));

const EnhancedRadioButtonGroupInput = (props) => {
  const {
    choices = [],
    classes: classesOverride,
    format,
    helperText,
    label,
    margin = 'dense',
    onBlur,
    onChange,
    onFocus,
    options,
    optionText,
    optionValue,
    disableValue,
    parse,
    resource,
    row,
    source,
    translateChoice,
    validate,
    ...rest
  } = props;
  const classes = useStyles(props);

  warning(
    source === undefined,
    `If you're not wrapping the RadioButtonGroupInput inside a ReferenceInput, you must provide the source prop`,
  );

  warning(
    choices === undefined,
    `If you're not wrapping the RadioButtonGroupInput inside a ReferenceInput, you must provide the choices prop`,
  );

  const {
    id,
    isRequired,
    meta: {error, touched},
    input,
  } = useInput({
    format,
    onBlur,
    onChange,
    onFocus,
    parse,
    resource,
    source,
    validate,
    ...rest,
  });

  return (
    <FormControl
      component="fieldset"
      margin={margin}
      error={touched && !!error}
      {...rest}>
      <FormLabel component="legend" className={classes.label}>
        <FieldTitle
          label={label}
          source={source}
          resource={resource}
          isRequired={isRequired}
        />
      </FormLabel>

      <RadioGroup id={id} row={row} {...options}>
        {choices.map((choice) => (
          <EnhancedRadioButtonGroupInputItem
            {...input}
            key={get(choice, optionValue)}
            disabled={input.disabled || choice[disableValue]}
            choice={choice}
            optionText={optionText}
            optionValue={optionValue}
            source={source}
            translateChoice={translateChoice}
          />
        ))}
      </RadioGroup>
      <FormHelperText>
        <InputHelperText
          touched={touched}
          error={error}
          helperText={helperText}
        />
      </FormHelperText>
    </FormControl>
  );
};

EnhancedRadioButtonGroupInput.defaultProps = {
  options: {},
  optionText: 'name',
  optionValue: 'id',
  disableValue: 'disabled',
  row: true,
  translateChoice: true,
};

export default EnhancedRadioButtonGroupInput;

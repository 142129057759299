import {useEffect, useState} from 'react';
import {useKeycloak} from '@react-keycloak/web';
import AuthProvider from 'services/AuthProvider';

export default function useAuthProvider() {
  const [authProvider, setAuthProvider] = useState();
  const [keycloak, initialized] = useKeycloak();

  useEffect(() => {
    if (initialized && !authProvider) {
      setAuthProvider(new AuthProvider({client: keycloak}));
    }
  }, [initialized, keycloak, authProvider]);

  return authProvider;
}

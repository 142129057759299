import React from 'react';
import {Toolbar, CreateButton, SaveButton, DeleteButton} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';

import ArchiveButton from './ArchiveButton';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row-reverse',
  },
}));

const valueOrDefault = (value, defaultValue) =>
  typeof value === 'undefined' ? defaultValue : value;

const FormToolbar = ({
  actions = ['save'],
  alwaysEnableSaveButton,
  disableDeleteButton,
  redirectParams,
  ignorePristine,
  ...others
}) => {
  const classes = useStyles(others);

  // Use form pristine to enable or disable the save button
  // if alwaysEnableSaveButton is undefined
  const disabled = !valueOrDefault(
    alwaysEnableSaveButton,
    ignorePristine || !others.pristine,
  );

  let enhancedParams = Array.isArray(redirectParams)
    ? [...redirectParams]
    : null;
  if (enhancedParams?.[2]) {
    enhancedParams[2] = redirectParams[2].split('.').reduce((acc, el) => {
      if (acc) {
        return acc[el];
      }
      return others[el];
    }, null);
  }

  return (
    <Toolbar {...others} className={classes.root}>
      {actions.includes('create') && <CreateButton disabled={disabled} />}
      {actions.includes('save') && <SaveButton disabled={disabled} />}
      {actions.includes('archive') && (
        <ArchiveButton redirectParams={enhancedParams} />
      )}
      {actions.includes('delete') && (
        <DeleteButton disabled={disableDeleteButton} />
      )}
    </Toolbar>
  );
};

export default React.memo(FormToolbar);

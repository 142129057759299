import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {Link} from 'react-router-dom';
import {useLogout, useGetIdentity} from 'react-admin';

const useStyles = makeStyles((theme) => ({
  profile: {
    textTransform: 'initial',
  },
}));

export default function ProfileMenu() {
  const {identity} = useGetIdentity();
  const classes = useStyles();
  const logout = useLogout();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    history.replace('/'); // XXX needed to not remember the page where someone was
    logout();
    setAnchorEl(null);
  };

  if (!identity) {
    return null;
  }

  return (
    <>
      <Button
        color="primary"
        className={classes.profile}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}>
        {identity.displayName}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <MenuItem component={Link} to="/me">
          Profilo utente
        </MenuItem>
        <MenuItem component={Link} to="/Video">
          Video
        </MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </>
  );
}

import React from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Box from '@material-ui/core/Box';
import {calcVar} from 'helpers';

function Variation({prevSum, currSum, color, hideArrow}) {
  const variation = calcVar(prevSum, currSum) || 0;
  const currentColor = typeof color === 'function' ? color(variation) : color;

  const Icon = variation > 0 ? ArrowDropUpIcon : ArrowDropDownIcon;

  return (
    <Box display="flex" width={1}>
      <Box flex={1}>
        {!hideArrow && <Icon style={{color: currentColor, fontSize: 40}} />}
      </Box>
      <Box fontSize={24} lineHeight="40px" color={currentColor}>
        {variation}%
      </Box>
    </Box>
  );
}

export default Variation;

import defineAbilityFor from './permissions';

export default class KeycloakAuthProvider {
  constructor({client}) {
    if (!client) {
      throw new Error('Missing parameters');
    }

    this.client = client;
    this.permissions = (identity) =>
      defineAbilityFor({identity, memberships: []});
  }

  login() {
    return this.client.login({locale: 'it'});
  }

  logout() {
    if (!this.client.authenticated) {
      return this.login();
    }

    return this.client.logout();
  }

  checkAuth() {
    if (this.client.authenticated) {
      return Promise.resolve();
    }

    return Promise.reject();
  }

  checkError(error) {
    const {graphQLErrors} = error;
    if (
      graphQLErrors &&
      graphQLErrors.find((e) => e.extensions.code === 'UNAUTHENTICATED')
    ) {
      this.client.clearToken();
      return Promise.reject();
    }

    return Promise.resolve();
  }

  getTokenIdentity() {
    const {authenticated, tokenParsed} = this.client;

    if (authenticated) {
      return {
        id: tokenParsed.sub.split(':')[2],
        firstName: tokenParsed.given_name,
        lastName: tokenParsed.family_name,
        displayName: tokenParsed.name,
        fullName: tokenParsed.name,
        email: tokenParsed.email,
        roles: tokenParsed.realm_access.roles,
      };
    }
  }

  getIdentity() {
    return Promise.resolve(this.getTokenIdentity());
  }

  getPermissions() {
    return Promise.resolve();
  }
}

import React from 'react';
import {Sidebar as BaseSidebar} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {getOrganization} from 'redux/filters';
import Can from 'components/atoms/Can';

const useSidebarStyles = makeStyles(
  (theme) => ({
    drawerPaper: {
      position: 'relative',
      width: (props) => (props.open ? 240 : 50),
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.only('xs')]: {
        marginTop: 0,
        height: '100vh',
        position: 'inherit',
        backgroundColor: theme.palette.background.default,
      },
      [theme.breakpoints.up('md')]: {
        border: 'none',
      },
      backgroundColor: 'white',
      borderRight: '1px solid #e6e7e8',
      zIndex: 9,
      [theme.breakpoints.up('lg')]: {
        width: 200,
      },
      [theme.breakpoints.up('xl')]: {
        width: 240,
      },
    },
  }),
  {name: 'RaSidebar'},
);

const Sidebar = (props) => {
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const classes = useSidebarStyles({...props, open});
  const {pathname} = useLocation();
  const organization = useSelector(getOrganization);

  const blackListedPaths = ['/Organization', '/LocalUnit/create'];

  return (
    <Can I="access" a="AdminDashboard" passThrough>
      {(isAdmin) => {
        const isConsultant = organization?.type === 'WASTE_CONSULTANCY';
        const isBlacklistedPath = blackListedPaths.some((path) =>
          pathname.includes(path, 0),
        );

        return !isAdmin && !isConsultant && isBlacklistedPath ? null : (
          <BaseSidebar classes={classes} {...props} />
        );
      }}
    </Can>
  );
};

export default Sidebar;

import React from 'react';
import moment from 'moment';
import {makeStyles} from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(() => ({
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export default function NotificationListItem({
  notification,
  headerText,
  ...others
}) {
  const classes = useStyles();

  return (
    <ListItem
      dense
      divider
      button
      ContainerComponent="div"
      selected={notification.isRead}
      {...others}>
      <ListItemText
        primary={
          notification.loading ? <Skeleton animation="wave" /> : headerText
        }
        secondary={
          <span class={classes.footer}>
            <time
              datetime={moment(notification.time).format('YYYY-MM-DD HH:mm')}>
              {moment(notification.time).format('L HH:mm')}
            </time>
            <span>
              {notification.loading ? (
                <Skeleton animation="wave" width={50} />
              ) : (
                notification.context.localUnit?.name ||
                notification.context.organization?.name
              )}
            </span>
          </span>
        }
      />
    </ListItem>
  );
}

import React from 'react';

export default function Changelog() {
  return (
    <div style={{alignSelf: 'center', marginTop: 120}}>
      <ul>
        <li>This incredible fantastic awesome number one news</li>
        <li>This incredible fantastic awesome number one news</li>
        <li>This incredible fantastic awesome number one news</li>
        <li>This incredible fantastic awesome number one news</li>
      </ul>
    </div>
  );
}

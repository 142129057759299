import {useState, useEffect} from 'react';
import merge from 'lodash/merge';
import defaultMessages from 'ra-language-italian';

import messages from '../locale/messages';
import {createProvider} from '../services/i18n';

export default function useI18nProvider() {
  const [provider, setProvider] = useState(null);

  useEffect(() => {
    if (!provider) {
      console.log(
        'creating i18n provider',
        merge({}, defaultMessages, messages),
      );
      const i18nProvider = createProvider({
        messages: merge({}, defaultMessages, messages),
      });

      setProvider(i18nProvider);
    }
  }, [provider, setProvider]);

  return provider;
}

import {required} from 'react-admin';
import memoize from 'lodash/memoize';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

const getMessage = (message, messageArgs, value, values) =>
  typeof message === 'function'
    ? message({
        args: messageArgs,
        value,
        values,
      })
    : messageArgs
    ? {
        message,
        args: messageArgs,
      }
    : message;

export const length = memoize(
  (length, message = 'ra.validation.length') => (value, values) =>
    !isEmpty(value) && value.length !== length
      ? getMessage(message, {length}, value, values)
      : undefined,
);

export const requiredIf = memoize(
  (field, match, message = 'ra.validation.required') => (value, values) => {
    const matchValue = values[field];
    const validateRequired = required(message);

    if (typeof match === 'function') {
      return match(matchValue, () => validateRequired(value, values));
    }

    if (isEqual(matchValue, match)) {
      return validateRequired(value, values);
    }
  },
);

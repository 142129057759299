import React, {useMemo, useCallback} from 'react';
import {Chart} from 'react-charts';

export default function ChartBar(props) {
  const series = useMemo(
    () => ({
      type: 'bar',
    }),
    [],
  );

  const getDatumStyle = useCallback((datum) => {
    return {
      ...datum,
      fill: datum.originalDatum.fill,
    };
  }, []);

  const axes = useMemo(
    () => [
      {primary: true, type: 'ordinal', position: 'bottom'},
      {position: 'left', type: 'linear', stacked: false},
    ],
    [],
  );

  return (
    <Chart
      series={series}
      axes={axes}
      getDatumStyle={getDatumStyle}
      {...props}
    />
  );
}

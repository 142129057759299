import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {ACTIONS, SUBJECTS} from 'services/permissions';
import {getOrganization, getLocalUnit} from 'redux/filters';

import usePermissions from './usePermissions';

export default function useHomePath() {
  const organization = useSelector(getOrganization);
  const localUnit = useSelector(getLocalUnit);
  const {permissions} = usePermissions();

  const isAdmin = useMemo(() => {
    return permissions?.can(ACTIONS.access, SUBJECTS.AdminDashboard);
  }, [permissions]);

  const homePath = useMemo(() => {
    if (isAdmin) {
      return '/EerCode';
    }

    if (localUnit || organization?.type === 'WASTE_CONSULTANCY') {
      return '/';
    }

    if (organization) {
      return '/Organization/' + organization.id;
    }
  }, [organization, localUnit, isAdmin]);

  return homePath;
}
